import { ErrorService } from '@akeela/auth';
import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-update-email-phone',
  templateUrl: './update-email-phone.component.html',
  styleUrls: ['./update-email-phone.component.css']
})
export class UpdateEmailPhoneComponent implements OnInit,OnDestroy {
  temp_subscriber: Subscription;
  emailMsg;
  email;
  PhoneMsg;
  phone;
  idperson;
  client;
  loading =true;
  constructor(   
      private activatedRoute: ActivatedRoute,  
      private _error: ErrorService,
      private _httpService: HttpService,
      private localStorage: LocalService,
      private router: Router,


    ) { }

  ngOnInit(): void {

    this.temp_subscriber = this.activatedRoute.queryParams.subscribe(params => {
     
      this.email = params?.email;
      this.phone = params?.phone;



     if(params?.username){
          this.getUser(params?.username);
     }

   
    });

  }



 getUser = async (username)   =>  {
 
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username':username
      },
      path: {},
      body: {}
    }
  };


  try {
    let response:any = await   this._httpService.executeRequest(params.name, params.params, true).toPromise();
    
    if (response) {
      

      this.client = response;
      response
      //this.idperson =response?.adminOrganization?.identifier || response?.person?.identifier;
         this.idperson = response?.person?.identifier;

      if(this.email){
        this.updateEmail();
      }

      if(this.phone){
        this.updatePhone();
      }

      
    
          
    }
    
  } catch (error) {
    this._error.show(error);
  }

}




 updateEmail = async ()   =>  {
  

  const params = {
    name: 'person$update_client_email$put',
    params: {
      request: {
        idPerson: this.idperson

      },
      path: {},
      body: {
        email: this.email,

      }
    }
  };


  try {
    let response:any = await   this._httpService.executeRequest(params.name, params.params, true).toPromise();
          
     this.emailMsg = "Email actualizado."
     this.loading = false;
     swal.fire({
      title: ' ',
      html:  this.emailMsg,
    });
    this.redirectClient();

  
  } catch (error) {
    this.loading = false;
    this._error.show(error,' ',error?.error?.message);
  }

}



updatePhone = async ()   =>  {
  const params = {
    name: 'person$update_client_phone$put',
    params: {
      request: {
        idPerson: this.idperson

      },
      path: {},
      body: {
        otherPhone: `+${this.phone}`,

      }
    }
  };


  try {
    let response:any = await   this._httpService.executeRequest(params.name, params.params, true).toPromise();
          
     this.PhoneMsg = "Teléfono actualizado."
     this.loading = false;
     swal.fire({
      title: ' ',
      html:  this.PhoneMsg,
    });
     this.redirectClient();
    
  
  } catch (error) {
    this.loading = false;
    this._error.show(error,' ',error?.error?.message);
  }

}


redirectClient(){

  const queryParams = {
    role: 'USER',
    profile: this.localStorage.getItem('profile'),
    audience: 'NATURAL',
  };

  this.router.navigate([environment.staticLinks.listClients], {queryParams});

}

    ngOnDestroy() {

    if (this.temp_subscriber) {
      this.temp_subscriber.unsubscribe();
    }
  
   

  }

}
