<div *ngIf="loadingAutoSave || loadingAutoSaveOut" class="toast botton-right  {{loadingAutoSave?'animate__animated animate__bounceInUp':'' }}  {{loadingAutoSaveOut?'animate__animated animate__bounceOutDown':'' }} ">
    <!-- <div class="toast-header">
     <strong class="mr-auto">Bootstrap</strong>
     <small class="text-muted">11 mins ago</small>
     <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
     <span aria-hidden="true">&times;</span>
     </button>
     </div>-->
    <div class="toast-body">
        Guardando <i class="ml-2 fas fa-spinner fa-spin"></i>
    </div>
</div>


<div *ngIf="!isConnected" [ngClass]="{'toast-danger': status != 'ONLINE','toast-success': status == 'ONLINE'}" class="ak-alert toast botton-left    {{!isConnected?'animate__animated animate__fadeIn animate__faster ':'' }}">

    <div class="toast-body">
        <span *ngIf="status == 'ONLINE'">
            Conexión restablecida
        </span>
        <span *ngIf="status == 'OFFLINE'">
            Se está intentando establecer la conexión...<br> Revise su conexión a la red
        </span>
    </div>
</div>
<!-- 

<ng-container *ngIf="reloadkeepalive && env?.auth?.inactivity?.active === true">
    <app-keepalive [timeinactivity]="timeinactivity" *ngIf="isAuth">
    </app-keepalive>
</ng-container>
-->

<!-- *SECCIÓN DE TEMPLATE TEMPLATE AUXILIAR* -->
<ng-container *ngTemplateOutlet="templates['mainContentApp'];" style="font-weight: bold"></ng-container>

<!-- FIN DE LA SECCIÓN DE TEMPLATE AUXILIAR -->

<!-- WINDOW REQUIREMENTS -->
<ng-container *ngTemplateOutlet="templateRequirements['requirementsWindowContent'];"></ng-container>
<ng-container *ngTemplateOutlet="templateRequirements['requirementsOtherWindowContent'];"></ng-container>

<div *ngIf="upload.visor" class="animated" [class.zoomOut]="!upload.visor">
    <ng-container *ngIf="env?.visor==='alternative'">
        <akeela-visor-google [filename]="upload.visor_params.name" [filedate]="upload.visor_params.date" [visorparams]="upload.visor_params" [back]="this.upload._back" [next]="this.upload._next" (close)="window_close()" (eventaction)="action($event)">
        </akeela-visor-google>
    </ng-container>
    <ng-container *ngIf="env?.visor!=='alternative'">
        <akeela-visor [filename]="upload.visor_params.name" [filedate]="upload.visor_params.date" [visorparams]="upload.visor_params" [back]="this.upload._back" [next]="this.upload._next" (close)="window_close()" (eventaction)="action($event)">
        </akeela-visor>
    </ng-container>
</div>



<!-- *SECCIÓN DE TEMPLATE TEMPLATE MODAL* -->
<ng-container *ngTemplateOutlet="templates['mainContentTransactionModalBotton']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainAssignContentModalApp']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalApp']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBotton']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBottonMeet']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBottonVerification']; " style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalCenter'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentNotes'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainExports'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainCVV'];"></ng-container>

<app-suggestion *ngIf="activeSuggestion" [selected]="activeSuggestion" (close)="closeSuggestion($event)" [config]="detailSuggestion" ></app-suggestion>


<div class="capa-menu" *ngIf="showSidebarLeft" (click)="toggleMenu()"> </div>
<akeela-sidebarLeft id="sidebarleft" class="d-none" (response)="eventMenu($event)">
</akeela-sidebarLeft>

<sd-akeela-template>
    <ng-template sd-akeela-content componentName="menuHeader">
        <app-logo-img (click)="redirectHome()" [theme]="env?.theme?.logoConf?.themeColorMenu" [type]="env?.theme?.logoConf?.typeMenu"></app-logo-img>
    </ng-template>

    <ng-template sd-akeela-content componentName="menuBody">
        <!-- <b>WORKSPACE NAME</b>
        <p style="color: gray">Espacio de trabajo de análisis</p>
       
        <div style="z-index: 151"  class="menu-body" *ngIf="true">
            <app-detail-popup></app-detail-popup>
        </div>
        
         <a (click)="redirectSpace()" class="text-dark active-cursor" >
             
                <i class="text-secondary fas fa-arrow-left pl-1"></i>
                Volver a espacos</a>
        <hr> -->
    </ng-template>

    <ng-template sd-akeela-content componentName="menuFooter">
        <div class="fz-14 menu-footer w-100 text-left mt-2 mb-2 p-2" style="font-size: 14px">

            <div class="text-secondary  mb-2  font-weight-bold">
                <i class=" pr-2 fas fa-user "></i>

                <span>USUARIO</span>
            </div>

            <div *ngIf="_auth.getSession()?.businessName">
                <span>
                    <b> {{_auth.getSession()?.businessName}}</b>
                </span>
            </div>

            <div>
                <span *ngIf="_auth.getSession()?.firstName"> {{_auth.getSession()?.firstName}}</span>
                <span *ngIf="_auth.getSession()?.lastName"> {{_auth.getSession()?.lastName}}</span>
                <br>
                <span *ngIf="_auth.getSession()?.email"> {{_auth.getSession()?.email}}</span>
            </div>
            <div>
                <div class="mt-2">
                    <span *ngIf="_auth.getSession()?.lastLoginDate">Ultima Sesión: {{_auth.getSession()?.lastLoginDate | date: 'd MMM, y h:mm a' }}</span>

                </div>
            </div>
        </div>
    </ng-template>
</sd-akeela-template>

<div>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>



<link rel="stylesheet" type="text/css" [href]="dinamicCssUrl+'/style.css' | safe">
<link rel="stylesheet" type="text/css" [href]="dinamicCssUrl+'/ak-styles-config.css' | safe">
<link rel="stylesheet" type="text/css" [href]="dinamicCssUrl+'/ak-library-styles-config.css' | safe">