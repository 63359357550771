import { AuthService } from '@akeela/auth';
import { I18nPipe } from '@akeela/i18n';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Utf8RegexService } from 'src/app/services/utf8-regex.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-list-exports',
  templateUrl: './list-exports.component.html',
  styleUrls: ['./list-exports.component.css']
})
export class ListExportsComponent implements OnInit {

  errorMsgValidatTel;
  sentValidateExport = false;
  activeModalValidateExport = false;
  centerModal;
  code;
  data;
  loadingSentValidateExport;
  loadingValidateCode;
  sentValidateErrorExport;
  showValidateTel: Subscription;
  export = []
  env: any = environment;

  form: FormGroup;
  emailSubscription: Subscription;
  sendMailSubscription: Subscription;
  submit_disabled = false;
  client;
  showForm;
  loadingExport;
  animate_init_bottom = true;
  buttomModal;
  dataExport = {};
  constructor(
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    public _auth: AuthService,
    private localStorage: LocalService,
    private akWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe,
    private _wfService: WorkflowService,
    private _utf8: Utf8RegexService,
    private datePipe: DatePipe,

  ) { }

  ngOnInit(): void {
    //this._wfService.setShowExportsForClient(true);

    this._wfService.showExportsForClient$.subscribe((result) => {

      if (result) {
        this.export = [];

        this.setInitialData();
        //this.form.reset();

      }
    });



    const temp = this.convertUTCDateToLocalDate(new Date()).toISOString().slice(0, 16);
    const dateIni = this.setToDateStartString(temp);

    const temp2 = this.convertUTCDateToLocalDate(new Date()).toISOString().slice(0, 16);
    const dateEnd = this.setToDateString(temp2);


    //Validators.pattern(this.email)
    this.form = this.formBuilder.group({
      type: [''],
      audience: ['NATURAL', [Validators.required]],
      datExportIni: [dateIni, [Validators.required]],
      datExportEnd: [dateEnd, [Validators.required]]
    });
  }

  setInitialData = () => {
    this.animate_init_bottom = false;
    this.buttomModal = true;
  }


  ngOnDestroy = (): void => {
    this.destroySub();

  }

  destroySub = () => {
    this.emailSubscription.unsubscribe();
    this.sendMailSubscription.unsubscribe();
  }


  setFormatCSV(res) {
    let data = {};
    const d = res.person;

    const date = this.toDateString(d?.dateOfThing?.startDate);
    const birthDate = this.toDateString(d?.birthDate);


    data = {
      fecha: date,
      identity: d?.identity.toString(),
      givenName: d?.givenName,
      familyName: d?.familyName,
      email: d?.email,
      login: d?.identity.toString(),
      audience: res?.applicantType,
      nationality: d?.nationality?.nationality || '',
      gender: d?.gender,
      taxID: d?.taxID.toString() || '',
      birthDate,
      civilStatus: d?.civilStatus || '',
      tel: d?.telephone?.toString() || '',
      tel2: d?.contactPoint?.telephone?.toString() || '',
      spouse: '',
      spouseIdentity: '',
      bankName: '',
      bankAccount: d?.relatedTo?.identifier?.toString() || '',
      d1: d?.homeAddress.streetAddress || '',
      d2: `${d?.homeAddress?.floo || ''} ${d?.homeAddress?.office || ''}`,
      name: d?.homeAddress?.name || '',
      postalCode: d?.homeAddress?.postalCode || '',
      city: d?.homeAddress?.city?.name || '',
      state: d?.homeAddress?.state?.name || '',


    }

    return data;

  }


  setFormatCSV_RV(res) {
  let data = {};
    const d = res.person;

    const date = this.toDateString(d?.dateOfThing?.startDate);
    const birthDate = this.toDateString(d?.birthDate);


 /* 
    headers: [
      "Tipo de subcuenta",
      "CI/RIF",
      "Nombres",
      "Apellidos",
      "Sexo",
      "Edo. Civil",
      "Fec. Nacimiento",
      "cod area",
      "telefono",
      "email",
      "Avenida",
      "Calle",
      "Carretera",
      "Vereda",
      "Edificio",
      "Casa",
      "Quinta",
      "Apartamento",
      "Urbanizacion",
      "Sector",
      "Parroquia", 
      "Ciudad",
       "Estado"
     
    ]
  };

  "Número de Producto",
  "Av/Calle/Esquina/Carretera/Vereda/Sector",
  "Nombre del Edif / Casa / Galpón / Local + Apartamento / Oficina + Número / Piso",
  "Municipio",
  "Código Postal",
  "Ciudad",
  "Estado"*/

let identity = d?.identity ? d?.identity?.toString() : d?.taxID?.toString();
    data = {

      typeId: identity?.slice(0, 1),
      identity: identity,
      givenName: d?.givenName,
      familyName: d?.familyName,
      gender: d?.gender?.slice(0,1),
      civilStatus: d?.civilStatus || '',
      birthDate,
      code :d?.otherPhone?.toString()?.slice(3,6),
      tel :d?.otherPhone?.toString()?.slice(6,13),
      email: d?.email,
      avenidaCalle: d?.homeAddress.streetAddress || '',
      avenida:"",
      calle:"",
      carretera:"",
      vereda:"",
      Edificio:"",
      Casa:"",
      Quinta:"",
      Apartamento:"",
      nameDirec:  ` ${ d?.homeAddress?.name || '' } ${ d?.homeAddress?.floo || ''} ${d?.homeAddress?.office || ''}`,
      Urbanizacion:"",
      Sector:"",
      Parroquia:"", 
      al_Sector: d?.homeAddress?.urbanization || '',
      city: d?.homeAddress?.city?.name || '',
      state: d?.homeAddress?.state?.name || '',
    }

    return data;

  }
  submit = async () => {
    this.submit_disabled = true;
    if (this.form.valid) {
      let form = this.form.getRawValue();

      try {

        const res: any = await this.getListExportServe();

        const dataExport = res?.map((d) => {
          return this.setFormatCSV_RV(d);
        })

        

        // if (this.dataInformation?.dateRegisteredCvv) {


        //   this.registerCVV = {
        //     extraDataCvv: this.dataInformation?.extraDataCvv && JSON.parse(this.dataInformation?.extraDataCvv),
        //     userRegisteredCvv: this.dataInformation?.userRegisteredCvv,
        //     dateRegisteredCvv: this.dataInformation?.dateRegisteredCvv

        //   }

        // } 

        this.dataExport = dataExport;

        await this.exportCSV_RV('CVV');

        this.setClose();

        swal.fire({
          reverseButtons: true,
          confirmButtonText: 'Ok',
          html: 'CSV creado exitosamente',
        });

        this.submit_disabled = false;


      } catch (error) {

        //this._error.show(error);
        this.submit_disabled = false;


      }

    }
  }


  exportCSV = async (type) => {
    let form = this.form.getRawValue();
    const fechaIni = this.toDateString(form?.datExportIni);
    const fechaFin = this.toDateString(form?.datExportEnd);

    return new Promise((resolve, reject) => {

      try {


        const temp2 = this.convertUTCDateToLocalDate(new Date()).toISOString().slice(0, 16);
        const date = this.setToDateString(temp2);



        let options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          title: `Orders  ${fechaIni} al ${fechaFin}  `,
          useBom: true,
          noDownload: false,



          headers: [
            "Fecha",
            "Número de Identificación",
            "Nombres",
            "Apellidos",
            "Correo Electrónico",
            "Login",
            "Tipo de Subcuentista (Tipo de Persona)",
            "Nacionalidad",
            "Sexo",
            "R.I.F.",
            "Fec. Nac",
            "Estado Civil",
            "N° Teléfono",
            "N° Celular 2",
            "Nombre del Cónyuge",
            "C.I. Cónyuge",
            "Institución Bancaria",
            "Número de Producto",
            "Av/Calle/Esquina/Carretera/Vereda/Sector",
            "Nombre del Edif / Casa / Galpón / Local + Apartamento / Oficina + Número / Piso",
            "Municipio",
            "Código Postal",
            "Ciudad",
            "Estado"
          ]
        };

        new ngxCsv(this.dataExport, `CVV-${date}  ${fechaIni} al ${fechaFin} `, options);


        resolve(true);
      } catch (error) {
        resolve(false);

      }
    });
  }



  exportCSV_RV = async (type) => {
    let form = this.form.getRawValue();
    const fechaIni = this.toDateString(form?.datExportIni);
    const fechaFin = this.toDateString(form?.datExportEnd);

    return new Promise((resolve, reject) => {

      try {


        const temp2 = this.convertUTCDateToLocalDate(new Date()).toISOString().slice(0, 16);
        const date = this.setToDateString(temp2);



        let options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          title: `Orders  ${fechaIni} al ${fechaFin}  `,
          useBom: true,
          noDownload: false,



          headers: [
            "Tipo de subcuenta",
            "CI/RIF",
            "Nombres",
            "Apellidos",
            "Sexo",
            "Edo. Civil",
            "Fec. Nacimiento",
            "cod area",
            "telefono",
            "email",
            "Sistem - Avenida/Calle",
            "Avenida",
            "Calle",
            "Carretera",
            "Vereda",
            "Edificio",
            "Casa",
            "Quinta",
            "Apartamento",
            "Sistem - Nombre del Edif / Casa / Galpón / Local / Otros",
            "Urbanizacion",
            "Sector",
            "Parroquia", 
            "Sistem - Parroquia / Urbanización",
            "Ciudad",
            "Estado"
           
          ]
        };

  
        new ngxCsv(this.dataExport, `CVV-${date}  ${fechaIni} al ${fechaFin} `, options);


        resolve(true);
      } catch (error) {
        resolve(false);

      }
    });
  }

  custom_sort(a, b) {
    return new Date(b?.create_date).getTime() - new Date(a?.create_date).getTime();
  }


  custom_sort_asc(a, b) {
    return new Date(a?.create_date).getTime() - new Date(b?.create_date).getTime();
  }



  private parseDateString(date: string): Date {
    date = date.replace('T', '-');
    let parts: any = date.split('-');
    let timeParts = parts[3].split(':');

    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

  }


  toDateString(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear().toString());
  }

  toDateString2(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (("0" + (date.getDate())).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear().toString());
  }
  setToDateStartString(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("01").slice(-2));
  }

  setToDateString(fecha): string {
    let temp2 = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);

    let temp = new Date(fecha).toISOString().slice(0, 16);
    let date: Date = this.parseDateString(temp2);
    return (date.getFullYear().toString() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2));
  }


  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;

  }

  setClose = () => {

    this.buttomModal = false;
    this._wfService.setShowExportsForClient(false);

  }


  getListExportServe = async () => {

    let form = this.form.getRawValue();

    try {
      const fechaIni = this.toDateString2(form?.datExportIni);
      const fechaFin = this.toDateString2(form?.datExportEnd);

      const params = {
        name: 'person$notregisteredcvv$get',
        params: {
          request: {
            audience: form?.audience,
            fechaIni,
            fechaFin
          },
          path: {},
          body: {}
        }
      };

      return this._httpService.executeRequest(params.name, params.params, true).toPromise();

    } catch (error) {

      swal.fire({
        reverseButtons: true,
        confirmButtonText: 'Ok',
        html: 'Error al consultar datos',
      });

      console.error(error);
      this.loadingExport = false;

    }

  }

}