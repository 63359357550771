<app-template>
    <ng-template app-content componentName="mainContentModalApp">
        <div class="capa-menu-2" *ngIf="openFilter" (click)="openFilter =!openFilter"> </div>
        <div class="">

            <div class="animate__animated  animate__faster  ak-modal-right p-2" [ngClass]="{'d-none':animate_init,'animate__slideInRight':openFilter,animate__slideOutRight:!openFilter } ">

                <div class="cl" style="height: 30px !important;">

                    <span class="float-right active-cursor" (click)="togle_window();">
                        <i class="text-secondary fas fa-times " style="font-size: 1.2rem;"></i>
                    </span>
                </div>


                <div class="body pb-4 pt-2 " style="overflow:auto;">



                    <div class="d-block  pb-5" style="max-height: 100%; background: #fcfcfc">


                        <div *ngIf="filterBy">
                            <div class=" font-weight-bold mb-4">
                                <i class="fas fa-filter iconsearch mr-1"></i> Filtrar</div>
                            <span *ngIf=" paginator?.number > 0" class="text-primary fz-14 ">Resultados
                                <span class="font-weight-bold"> {{ paginator?.number }} </span>
                            </span>

                            <div class="row p-0 m-0 pb-4">
                                <div class="col-11  p-0 m-0 pb-4">

                                    <div class="row">

                                        <div class="col-6 mt-3">
                                            <span class="label">Desde</span>
                                            <input onkeydown="return false" [(ngModel)]='formsData.fecha.dateInit' class="form-control" type="date" placeholder="Desde - 01/06/2021" (change)="setFilterMultiple()" />

                                        </div>
                                        <div class="col-6 mt-3 ">
                                            <span class="label">Hasta</span>

                                            <input onkeydown="return false" [(ngModel)]='formsData.fecha.dateEnd' class="form-control" type="date" placeholder="Hasta - 30/6/2021" (change)="setFilterMultiple()" />
                                        </div>



                                        <div class="col-12 mt-4" *ngIf="!workspaceDetail.agencia != ''">
                                            <span class=" label">Agencia </span>

                                            <select (change)="setFilterMultiple()" [(ngModel)]='formsData.agencia.key' class="form-control search withoutorder" name="status" (change)="setFilterStatus(formsData.status2.key); setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>

                                                <ng-container *ngFor="let agency of agencys; let i = index">

                                                    <option [value]='agency'>{{agency}}</option>

                                                </ng-container>
                                            </select>

                                        </div>


                                        <div class="col-12 mt-4 ">

                                            <span (change)="setFilterMultiple()" class=" label">Ejecutivo</span>

                                            <select [(ngModel)]='formsData.ejecutivo.key' class="form-control search withoutorder" name="status" (change)="setFilterStatus(formsData.status2.key); setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>

                                                <ng-container *ngFor="let executive of executives; let i = index">

                                                    <option [value]='executive'>{{executive}}</option>

                                                </ng-container>

                                            </select>

                                        </div>


                                        <div class="col-12 mt-4" *ngIf="validateFilterTypeAccount()">
                                            <span (change)="setFilterMultiple()" class=" label">Tipo de Cuenta </span>
                                            <select [(ngModel)]='formsData.accounttype.key' class="form-control search withoutorder" name="accounttype" (change)=" setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>
                                                <option value='ACCOUNT_LEVEL1' >Nivel 1</option>
                                                <option value='ACCOUNT_LEVEL2' >Nivel 2</option>
                                                <option value='WKF_PROD_FONDO_MUTUAL' *ngIf="env?.theme?.images==='RENDIVALORES'">Fondo Mutual</option>
                                            </select>
                                        </div>



                                        <div class="col-12 mt-4 " *ngIf="!indicatorsLoading">

                                            <span class=" label">Datos del cliente</span>
                                            <div class="row mt-2">

                                                <div class="col-6 fz-12 text-secondary">
                                                    Desde %
                                                </div>
                                                <div class="col-6 fz-12 text-secondary">
                                                    Hasta %
                                                </div>


                                                <div class="col-6">
                                                    <input [(ngModel)]='formsData.rangeDatosCliente.min' [min]="0" [max]="100" class="  form-control" type="number" placeholder="0" (change)="setFilterMultiple()" />

                                                </div>

                                                <div class="col-6">
                                                    <input [(ngModel)]='formsData.rangeDatosCliente.max' [min]="0" [max]="100" class="form-control" type="number" placeholder="100" (change)="setFilterMultiple()" />

                                                </div>

                                            </div>

                                        </div>


                                        <div class="col-12 mt-4 " *ngIf="!indicatorsLoading">

                                            <span class=" label">Recaudos</span>
                                            <div class="row mt-2">

                                                <div class="col-6 fz-12 text-secondary">
                                                    Desde %
                                                </div>
                                                <div class="col-6 fz-12 text-secondary">
                                                    Hasta %
                                                </div>


                                                <div class="col-6">
                                                    <input [(ngModel)]='formsData.rangeRecaudos.min' [min]="0" [max]="100" class="  form-control" type="number" placeholder="0" (change)="setFilterMultiple()" />

                                                </div>

                                                <div class="col-6">
                                                    <input [(ngModel)]='formsData.rangeRecaudos.max' [min]="0" [max]="100" class="form-control" type="number" placeholder="100" (change)="setFilterMultiple()" />

                                                </div>

                                            </div>

                                        </div>


                                        <div class="col-12 mt-4">

                                            <div class="label">Tipo de persona </div>


                                            <div class="row">


                                                <div class="col-6 mt-3" *ngIf="workspaceParams===null || workspaceParams?.personType?.audienceType === null || workspaceParams?.personType?.audienceType==='ALL' || workspaceParams?.personType?.audienceType==='NATURAL'">
                                                    <button [ngClass]="{'btn-secondary': formsData.audience.key == 'NATURAL','btn-outline-secondary': formsData.audience.key !== 'NATURAL' }" class="btn  btn-block" (click)="formsData.audience.key = 'NATURAL' ;setFilterMultiple()">
                                                        <i class="fas fa-user mr-2"></i>Natural</button>

                                                </div>
                                                <div class="col-6 mt-3" *ngIf="workspaceParams===null || workspaceParams?.personType?.audienceType === null || workspaceParams?.personType?.audienceType==='ALL' || workspaceParams?.personType?.audienceType==='LEGAL'">
                                                    <button [ngClass]="{'btn-secondary': formsData.audience.key == 'LEGAL','btn-outline-secondary': formsData.audience.key !== 'LEGAL'}" class="btn  btn-block" (click)="formsData.audience.key = 'LEGAL' ;setFilterMultiple()">
                                                        <i class="fas fa-building mr-2"></i>Juridica</button>

                                                </div>
                                            </div>


                                        </div>


                                        <div class="col-12 mt-4" *ngIf="false">

                                            <div class="label ">Verificación de identidad
                                                <b>completada</b>
                                            </div>

                                            <div class="row">
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.completeIdentification.key === 'true','btn-outline-secondary': formsData.completeIdentification.key !== 'true' }" class="btn  btn-block" (click)="formsData.completeIdentification.key = 'true' ;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.completeIdentification.key === 'false','btn-outline-secondary': formsData.completeIdentification.key !== 'false'}" class="btn  btn-block" (click)="formsData.completeIdentification.key = 'false' ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-12 mt-4" *ngIf="false">

                                            <div class="label ">Verificación de identidad
                                                <b>aprobada</b> {{formsData.verifiedIdentification.key}} </div>

                                            <div class="row">
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.verifiedIdentification.key === 'true','btn-outline-secondary': formsData.verifiedIdentification.key !== 'true' }" class="btn  btn-block" (click)="formsData.verifiedIdentification.key = 'true' ;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.verifiedIdentification.key === 'false','btn-outline-secondary': formsData.verifiedIdentification.key !== 'false'}" class="btn  btn-block" (click)="formsData.verifiedIdentification.key = 'false' ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>

                                        </div>


                                        <div class="col-12 mt-4" *ngIf="env?.theme?.images === 'RENDIVALORES'">

                                            <div class="label ">LA Sistemas</div>

                                            <div class="row">
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.externalCode.key ,'btn-outline-secondary': !formsData.externalCode.key}" class="btn  btn-block" (click)="formsData.externalCode.key = true;setFilterMultiple()">Si</button>

                                                </div>
                                                <div class="col-4 mt-3">
                                                    <button [ngClass]="{'btn-secondary': formsData.externalCode.key === false,'btn-outline-secondary': !(formsData.externalCode.key === false)}" class="btn  btn-block" (click)="formsData.externalCode.key = false ;setFilterMultiple()">No</button>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="orderBy">
                            <div class=" font-weight-bold mb-4">
                                <i class="fas fa-sort iconsearch mr-1"></i> Ordenar</div>


                            <div class="row">

                                <div class="col-12 mt-1">

                                    <div class="mt-2">
                                        <button [ngClass]="{'btn-primary': orderByData == 1,'btn-outline-secondary': orderByData != 1}" class="btn ml-1 " (click)="setOrder(1)">
                                            <i class="fas fa-sort-amount-down-alt mr-2"></i>Fecha descendente</button>
                                    </div>

                                    <div class="mt-2">

                                        <button [ngClass]="{'btn-primary': orderByData == 2,'btn-outline-secondary': orderByData != 2}" class="btn  ml-1 " (click)="setOrder(2)">
                                        <i class="fas fa-sort-amount-up-alt mr-2"></i>Fecha ascendente</button>

                                    </div>

                                    <div *ngIf="indicatorsProgresRsp" class="mt-2">
                                        <button [ngClass]="{'btn-primary': orderByData == 3,'btn-outline-secondary': orderByData != 3}" class="btn  ml-1 " (click)="setOrder(3)">
                                            <i class="fas fa-sort-amount-down-alt mr-2"></i>Progreso descendente</button>
                                    </div>

                                    <div *ngIf="indicatorsProgresRsp" class="mt-2">

                                        <button [ngClass]="{'btn-primary': orderByData == 4,'btn-outline-secondary': orderByData != 4}" class="btn  ml-1 " (click)="setOrder(4)">
                                        <i class="fas fa-sort-amount-up-alt mr-2"></i>Progreso ascendente</button>

                                    </div>


                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </div>




        <div class="capa-menu-2" *ngIf="buttomModal" (click)="buttomModal =!buttomModal;tap == null"> </div>
        <div [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">

            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div class=" mt-4 " *ngIf="tap == 2">
                    <div class="text-left col-12 col-md-10 offset-md-1 ">
                        <akeela-step *ngIf="true" [inputWorkflowCode]="'ACCOUNT_OPENING'" [inputStageCode]="'CREATE_USER_ACCOUNT_OPENING'" [inputStepCode]="'CREATE_USER_ACCOUNT_OPENING'" [profile]="'SALES_MANAGER'" [audience]="'LEGAL'" [role]="'USER'">
                        </akeela-step>
                    </div>
                </div>
                
                <div *ngIf="tap == 1">
                    <div class="container text-center">

                        <div class=" w-100 text-center mb-3 ">
                            <div class="DomainUserAvatar-avatar bg-light AvatarPhoto AvatarPhoto--coord" role="img">
                                <i class="fas fa-archive"></i>
                            </div>
                            <div class="text-secondary mt-2 font-weight-bold fz-16">Archivar Solicitud </div>
                        </div>

                        <div class="row">

                            <div class="col-12 col-md-6 offset-md-3">

                                <div class=" text-left">
                                    <div class="p-2 fz-14 text-left size-caja-dopd">

                                        <div class="row">

                                            <div class="col-12 mt-1 form-group">
                                                <span class=" label mb-1"> Razón </span>

                                                <select class="form-control search withoutorder" name="status" [(ngModel)]='formsDataResponse.type'>
                                                    <option value='' [selected]="true">--</option>
                                                    <ng-container *ngFor="let row of responseType; let i = index">

                                                        <option [value]='row?.item'>{{row?.value | i18n }}</option>

                                                    </ng-container>

                                                </select>

                                            </div>
                                            <div class="col-12 mt-1 form-group">
                                                <span class=" label mb-1 ">Descripción </span>

                                                <textarea [(ngModel)]='formsDataResponse.description' name="ejemplo" class="form-control " style="height: 100px !important; "></textarea>

                                            </div>

                                            <div class="col-12 mt-3 form-group">
                                                <button [disabled]="formsDataResponse.type == '' " (click)="submitFormsDataResponse()" class="btn  btn-primary btn-block ">Guardar</button>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </ng-template>
</app-template>


<app-transaction-detail *ngIf="transactionDetail" [selected]="transactionDetail" [transaction]="transactionSelected" (change)="setTransactionDetail($event)"></app-transaction-detail>
<app-client-detail [selected]="clientDetail" [username]="clientSelected" (change)="setClientDetail($event)" (la)="setLA($event)"></app-client-detail>
<!---->


<ng-container>
    <div style="font-size: 14px">
        <div class="jumbotron jumbotron-fluid p-3 ">

            <div class="d-flex bd-highlight  flex-wrap">

                <div class="w-95">
                    <div class="elipsse">
                        <h5 [title]="workspaceDetail.wpname" class="font-weight-normal">
                            <i class="fas fa-layer-group pr-1"></i> {{workspaceDetail.wpname}}


                            <!-- <span class=" text-secondary small ">(  {{((queue && queueName != 'Q_ALL_OP_M')?(queue.name):('' | i18n))}} )</span>-->
                        </h5>
                        <div class=" mb-1 mt-2">

                            <ul class="scrollmenu pl-0 mb-0 ">
                                <li class="d-inline">
                                    <div *ngIf="workspaceDetail.agencia != ''" class="item-rd text-secondary fz-14 ">{{workspaceDetail.agencia}}</div>
                                    <div *ngIf="workspaceDetail.tipo != ''" class="item-rd text-secondary fz-14 ">{{ workspaceDetail.tipo}}</div>
                                    <div *ngIf="workspaceDetail.product != ''" class="item-rd text-secondary fz-14 ">{{ workspaceDetail.product}}</div>
                                    <div *ngIf="workspaceDetail.plan != ''" class="item-rd text-secondary fz-14 ">{{ workspaceDetail.plan}}</div>
                                    <div *ngIf="workspaceDetail.statusSolicitud != ''" class="item-rd text-secondary fz-14 ">{{ workspaceDetail.statusSolicitud}}</div>


                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

                <div class="w-5 text-right">
                    <i class="fas fa-ellipsis-v "></i>

                </div>
            </div>



        </div>


        <div class="row   mt-3 mb-1 ">

            <div class="col-12 col-md-7">
                <div *ngIf="transactionTakenYou?.taken" class=" alert alert-warning p-3 mt-2 mb-1 animate__animated animate__fadeIn ">
                    <button (click)="setUntakenUser(transactionTakenYou?.transaction) " class="btn-sm btn btn-secondary  float-right">
                        Liberar
                    </button>
                    <span class="text-secondary">Usted tiene tomada la solicitud  </span> {{transactionTakenYou?.transaction}}
                </div>
            </div>
            <!-- 
            <div class="col-12 ">
                <div (click)="togle_botton_window();tap=2;" class="mb-2 float-right DomainUserAvatar-avatar text-dark AvatarPhoto--client2  AvatarPhoto AvatarPhoto--small" role="img"><span class="fas fa-user-plus"></span></div>

            </div>
 -->
            <div class="col-12 text-primary col-md-7 pt-md-4  mb-4">
                <input (keyup.enter)="updateFilter (fieldSearch) " [(ngModel)]='fieldSearch' class="pt-2 form-control active-cursor padding-filter-searh-input " type="text " placeholder='CI, Telf, Nombre, Apellido, No. Solicitud' />
                <i (click)="updateFilter (fieldSearch) " class="text-primary fas fa-search icon-filter-searh active-cursor"></i>

            </div>

            <!-- 
            <div class="col-12 col-md-3 mb-4 d-none d-md-block">

                <span class="text-secondary small fz-12 ">Verificación de identidad</span>
                <select [(ngModel)]='formsData.completeIdentification.key' class="mt-1 form-control search withoutorder" name="status" (change)="setFilterStatus(formsData.status2.key); setFilterMultiple()">
                                                <option value='' [selected]="true">--</option>
                                                <option value='true' >Completada</option>
                                                <option value='false' >Sin completar</option>              

                </select>



            </div>
            -->

            <div class=" text-primary col-12 " (click)=" estadistica=! estadistica ">
                <!-- <span class="float-right "> Metricas </span>-->

                <h5 class="text-dark mr-1 "> {{((queue && queueName != 'Q_ALL_OP_M')?(queue?.name):('' | i18n))}}</h5>

            </div>

            <div class="col-12 text-primary pt-3 pb-2 ">
                <div class="d-inline-flex w-100 ">


                    <button *ngIf="historyData?.length > 0 " style="height: 35px !important; " [ngClass]="{'btn-light': !historyTransaction,'btn-secondary': historyTransaction}" class=" btn btn-sm" (click)="historyTransaction =!historyTransaction;fieldSearch='' ">
                        
                        <i class="fas fa-history iconsearch "></i>
                        Recientes
                    </button>

                    <button style="height: 35px !important; " class="btn-light   ml-2 btn btn-sm " (click)=" togle_window(1);fieldSearch='' ">
                       
                        <i class="fas fa-filter iconsearch "></i>
                        Filtrar
                    </button>


                    <button style="height: 35px !important; " class="btn-light ml-2 btn btn-sm" (click)=" togle_window(2);fieldSearch='' ">
                       
                        <i class="fas fa-sort iconsearch "></i>
                         Ordenar
                    </button>
                </div>
            </div>
            <div class="col-12 ">
                <div class="w-100 animate__animated animate__fadeInDown " *ngIf="filterEtique.length> 0 ">

                    <ul class="scrollmenu pl-0 pt-2 ">
                        <li class="d-inline  mt-2" *ngFor="let row of filterEtique ; let i = index">
                            <div class="item-rd text-secondary fz-14 ">{{row?.label}}

                                <i class="fa fa-times ml-2 active-cursor" (click)="deleteFilter(row?.key,row?.type)" aria-hidden="true"></i>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="historyData?.length > 0 && historyTransaction" class="pt-4 pb-2">

            <div class="text-secondary pb-3 pt-2 ml-2"> Vistos recientemente </div>

            <ul class="scrollmenu pl-0  ">
                <li class="d-inline">

                    <div class="d-flex">
                        <ng-container *ngFor="let row of historyData ; let i = index">

                            <div class="ml-2 mr-2">

                                <div class="text-center active-cursor" (click)="setClient(row?.username)">

                                    <div *ngIf="!historyDataSelfi[row?.solicitud]" [ngClass]="{'border-verification-success': row?.verifiedIdentification ,'border-verification-grey':row?.completeIdentification &&  !row?.verifiedIdentification}" class=" DomainUserAvatar-avatar text-dark AvatarPhoto--client2  AvatarPhoto AvatarPhoto--small"
                                        role="img">{{getInitials(row?.name)}}
                                    </div>

                                    <div style=" background: url({{historyDataSelfi[row?.solicitud]}}) 50% 50% no-repeat; " class="ak-shadow animate__animated animate__fadeIn content-avata-img " *ngIf="historyDataSelfi[row?.solicitud]">
                                    </div>
                                </div>

                                <div class="text-center active-cursor">
                                    <div class="font-weight-bold  active-cursor text-capitalize fz-12 mt-1" (click)="fieldSearch = row?.name; updateFilter(row?.name)"> <span>{{row.name}}</span>



                                    </div>
                                    <div *ngIf="row?.action != 'view_client'" class="active-cursor text-center text-secondary fz-12 " (click)="fieldSearch = row?.solicitud; updateFilter (row?.solicitud)">

                                        {{row?.solicitud}}
                                        <!-- 
                                        <i *ngIf="row?.action == 'edit_transaction'" class="fas fa-edit text-secondary ml-1"></i>
-->
                                    </div>

                                </div>

                            </div>
                        </ng-container>

                    </div>

                </li>
            </ul>
        </div>

        <div class="d-flex bd-highlight mt-3">

            <div class="">
                <i *ngIf="!reload_loading" (click)="reloadTransaction()" class="fas fa-redo text-secondary ml-2 active-cursor "></i>
                <span *ngIf="updateDate" class="text-secondary  fz-14 ml-2" [title]="updateDate?.time"> Actualizado
                    <span [innerHTML]="updateDate?.value"></span>
                </span>
            </div>

            <div class="text-secondary ml-auto">

                {{ (paginator?.pageable?.to===0)?(paginator?.pageable?.to):(paginator?.pageable?.from) }} - {{ paginator?.pageable?.to }} de
                <b>{{ paginator?.number }} </b>
                <i class="fas fa-chevron-left mr-1 text-secondary active-cursor" [class.active-cursor]="!paginator?.first" [class.disabled]="paginator?.first" (click)="action_paginator('BACK')"></i>
                <i class="fas fa-chevron-right text-secondary " [class.active-cursor]="!paginator?.last" [class.disabled]="paginator?.last" (click)="action_paginator('NEXT')"></i>

            </div>

        </div>
        <hr>


        <ng-container *ngIf="reload_loading">
            <div class=" w-100 mt-2">
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>
        <ng-container *ngFor="let row of rows ; let i = index">

            <!--
            <pre>{{ indicatorsProgresRsp[row?.solicitud] | json }}</pre>
 -->

            <div *ngIf="!loading_table && !loading_order" [ngClass]="{'visited': historyDataSelect[row?.solicitud] }" class="card  mt-5 mb-3 ak-shadow-box ">
                <div [ngClass]="{'other': takenListUsers[row?.solicitud] == 'other'}" class="capa-card">
                </div>
                <div [ngClass]="{'opacity-card': takenListUsers[row?.solicitud] == 'other'}" class="card-body card-body-e">


                    <div class="d-flex bd-highlight ">
                        <div class="d-none d-md-block  w-5 w-sm-15">

                            <div *ngIf="!fileAvatarPreview[row?.username]" class="DomainUserAvatar-avatar text-dark AvatarPhoto--client  AvatarPhoto AvatarPhoto--small" role="img">{{ getInitials(row?.solicitante)}}
                                <div class="huella">
                                    <i class="fas fa-fingerprint" [ngClass]="{'text-success': row?.verifiedIdentification ,'text-primary':row?.completeIdentification &&  !row?.verifiedIdentification}"></i>
                                </div>
                            </div>
                            <div (click)="getDocumentClient(row?.username,'SELFIE')" style="float:left ; border: 1px solid #ddd; background: url({{fileAvatarPreview[row?.username]}}) no-repeat center" class="activate-cursor animate__animated animate__fadeIn content-avata-img2 mr-1"
                                *ngIf="fileAvatarPreview[row?.username]">
                                <div class="huella2">
                                    <i class="fas fa-fingerprint" [ngClass]="{'text-success': row?.verifiedIdentification ,'text-primary':row?.completeIdentification &&  !row?.verifiedIdentification}"></i>
                                </div>
                            </div>


                        </div>
                        <div class="w-95  w-sm-100 pl-2">

                            <div class="d-flex bd-highlight font-weight-bold active-cursor text-capitalize fz-14 mt-2  " (click)=" setClient(row?.username);">

                                <div class="bd-highlight  ">


                                    <i *ngIf="row?.audience != 'NATURAL'" class="iconNomber fas fa-building text-secondary mr-1 fz-16"></i>
                                    <i *ngIf="row?.audience == 'NATURAL'" class="iconNomber fas fa-user text-secondary mr-1 fz-16"></i>


                                    <span class="text-dark font-weight-bold" href="javascript:void(0)">{{row?.solicitante}}  </span>
                                    <span *ngIf="( env?.theme?.images === 'RENDIVALORES' || env?.theme?.images === 'KOI') && row?.externalCode" class="text-success ml-1 font-weight-bold" style="font-size: 11px !important;letter-spacing: 1.5px;" [title]="row?.externalCode">
                                      LA
                                    </span>
                                </div>

                                <div class="bd-highlight ml-auto  ">

                                    <div *ngIf="!takenListUsers[row?.solicitud] == ''">
                                        <i *ngIf="takenListUsers[row?.solicitud] == 'other'" class=" fas fa-user-lock  text-danger"></i>
                                        <i *ngIf="takenListUsers[row?.solicitud] == 'your'" class=" fas fa-user-cog  text-secondary"></i>
                                        <span *ngIf="takenListUsers[row?.solicitud] == 'other'" class=" fz-12 text-danger">
                                            {{ substringMore(15,row?.takenUser)?.text }}

                                            <span class="text-secondary" *ngIf="substringMore(15,row?.takenUser)?.more">...</span>

                                        </span>
                                        <span class="text-primary fz-12" (click)="$event.stopPropagation();setUntakenUser(row?.solicitud) " *ngIf="takenListUsers[row?.solicitud] == 'your'"> Liberar </span>
                                    </div>
                                    <div class="float-right">

                                        <span class="font-weight-bold fz-12 text-right" [title]="row?.fecha?.time">
                                        <span  [innerHTML]="row?.fecha?.value"></span>
                                        </span>
                                        <!-- && row?.status?.additionalType !='AC_REJECTED'  && row?.status?.additionalType !='AC_RECEIVED'-->
                                        <i *ngIf="row?.active " title="Archivar" (click)=" $event.stopPropagation();togle_botton_window(row?.solicitud);tap=1;" class="fas fa-archive active-cursor pl-3 pr2 text-primary"></i>

                                    </div>

                                </div>
                            </div>
                            <span *ngIf="!row?.active && row?.razon" class="badge badge-light p-2 mt-2 ">
                                <i class="fas fa-archive mr-1"></i> {{row?.razon}}</span>

                            <div class=" active-cursor text-capitalize fz-12 mt-2 mb-4 ">

                                <div class="d-flex">

                                    <div class="text-primary box-transaction-plan mt-2" (click)="setHistoryData(row,'view_transaction','Ver solicitud'); $event.stopPropagation();open(row?.solicitud)">



                                        <div class="d-flex bd-highlight ">

                                            <div class=" w-20 w-sm-15">
                                                <ng-container *ngIf="row?.status?.additionalType !='AC_REJECTED' && row?.status?.additionalType != 'AC_RECEIVED' ">
                                                    <span style="font-size:11px" [ngClass]="
                                                    {'badge-secondary-per': indicatorsProgresRsp[row?.solicitud]?.compleat?.percent < 50,
                                                    'badge-warning': indicatorsProgresRsp[row?.solicitud]?.compleat?.percent > 50 && indicatorsProgresRsp[row?.solicitud]?.compleat?.percent < 75 ,
                                                    'badge-danger': indicatorsProgresRsp[row?.solicitud]?.compleat?.percent > 75,
                                                    'badge-dark': row?.status?.additionalType != 'PI_IN_PROCESS'}" *ngIf="indicatorsProgresRsp[row?.solicitud]?.compleat" class="ml-1 badge badge-pill   mt-2">
                    
                                                        {{indicatorsProgresRsp[row?.solicitud]?.compleat?.percent}}%
                                                    </span>

                                                    <span style="font-size:11px" *ngIf="!indicatorsProgresRsp[row?.solicitud]" class="pt-1 pb-1 pl-2 pr-2 ml-1 badge badge-pill badge-secondary-per   mt-2">
                                                        <span class=" fas fa-exclamation "></span>
                                                    </span>


                                                </ng-container>

                                                <ng-container *ngIf="row?.status?.additionalType == 'AC_RECEIVED' ">
                                                    <i style="font-size:19px" class="pl-2 pt-2 fas fa-thumbs-up text-success"></i>

                                                </ng-container>

                                                <ng-container *ngIf="row?.status?.additionalType == 'AC_REJECTED' ">
                                                    <i style="font-size:19px" class="pl-2 pt-2 fas fa-thumbs-down  text-danger"></i>
                                                </ng-container>


                                            </div>

                                            <div class="  w-80 w-sm-85">

                                                <div>
                                                    <span style="line-height: 12px;">{{row?.plan?.name}}</span>

                                                </div>

                                                <div class="text-secondary">

                                                    <span>{{row?.plan?.shortDescription}}</span> /
                                                    <span class="font-weight-bold">{{row?.solicitud}}  </span>



                                                    <!-- 
                                                            <i [ngClass]="{ 'fa-stop':row?.status?.additionalType =='AC_REJECTED','fa-align-left':row?.status?.additionalType =='PI_IN_PROCESS','fa-reply':row?.status?.additionalType =='AC_INCOMPLETE' ,'fa-check':row?.status?.additionalType =='AC_PENDING','fa-check-double':row?.status?.additionalType =='AC_RECEIVED'}"
                                                    class=" fas text-primary pr-2 "></i>
                                                        -->




                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>


                            </div>

                            <div *ngIf="row?.status?.firstAnalystRevisionFlag" class="   mb-1 fz-12  ">

                                <i class="fas fa-user-tie text-primary mr-1 "></i> Revisado por Analista de Riesgo

                            </div>

                            <div class=" active-cursor text-capitalize fz-14 mt-2 mb-2 ">

                                <span [innerHTML]="row?.status2 | i18n" class="badge badge-pill badge-secondary fz-14 mr-2"></span>


                                <span [title]=" timeElapsed[row?.identifier]?.detail" [class.badge-secondary]="!timeElapsed[row?.identifier]?.timeDangerHHValue && timeElapsed[row?.identifier]?.timeDangerHHValue " [class.badge-danger]="timeElapsed[row?.identifier]?.timeDangerHHValue "
                                    [class.badge-warning]="timeElapsed[row?.identifier]?.timeWarningHHValue " [class.badge-light]=" !timeElapsed[row?.identifier]?.timeDangerHHValue && !timeElapsed[row?.identifier]?.timeWarningHHValue " class="badge  p-1 ml-2">
                            {{ timeElapsed[row?.identifier]?.value!==''?timeElapsed[row?.identifier]?.value:'-' }}
                            <i class="pl-1 far fa-clock" [class.blink-me2]="timeElapsed[row?.identifier]?.timeWarningHHValue " [class.blink-me]="timeElapsed[row?.identifier]?.timeDangerHHValue "></i>

                               </span>



                            </div>


                            <div class="active-cursor text-capitalize fz-14 mt-2 ">

                                <div *ngIf="indicatorsProgresRsp[row?.solicitud]">

                                    <span class="badge badge-secondary badge-secondary-light ml-1 mr-1 mt-2 ">
                                            Datos de Clientes
                                            <span  *ngIf="indicatorsProgresRsp[row?.solicitud]['CLIENT_DATA']" >  ( {{(indicatorsProgresRsp[row?.solicitud]['CLIENT_DATA']['minValue']+'/'+indicatorsProgresRsp[row?.solicitud]['CLIENT_DATA']['maxValue'])}} )</span>
                                    <span *ngIf="usersTransactions[row?.username]?.completeBriefcase">
                                                <i *ngIf="!usersTransactions[row?.username]?.verifiedBriefcase" class=" pl-1  fas fa-check mt-1"></i>
                                                <i *ngIf="usersTransactions[row?.username]?.verifiedBriefcase" class=" pl-1  small fas fa-check-double mt-1"></i>
                                            </span>
                                    </span>


                                    <span *ngIf="indicatorsProgresRsp[row?.solicitud]['DOC_SUPPORT']" class=" badge badge-secondary badge-secondary-light ml-1 mr-1 mt-2">
                                            
                                            <span [innerHTML]="row?.plan?.shortDescription | i18n" ></span>
                                    <span>
                                              ( {{ (indicatorsProgresRsp[row?.solicitud][row?.workflow]?.minValue+'/'+indicatorsProgresRsp[row?.solicitud][row?.workflow]?.maxValue)}} )</span>

                                    </span>


                                    <span *ngIf="indicatorsProgresRsp[row?.solicitud]['DOC_SUPPORT']" class=" badge badge-secondary badge-secondary-light  ml-1 mr-1 mt-2">
                                            
                                            <span  >Recaudos ( {{( indicatorsProgresRsp[row?.solicitud]['DOC_SUPPORT']['minValueReq']+'/'+indicatorsProgresRsp[row?.solicitud]['DOC_SUPPORT']['maxValueReq'])}} ) </span>

                                    </span>



                                    <span *ngIf="indicatorsProgresRsp[row?.solicitud]['CLIENT_DATA_REDUCED']" class=" badge badge-secondary badge-secondary-light ml-1 mr-1 mt-2">
                                      
                                            <span >Datos de Clientes  {{(indicatorsProgresRsp[row?.solicitud]['CLIENT_DATA_REDUCED']['minValue']+'/'+indicatorsProgresRsp[row?.solicitud]['CLIENT_DATA_REDUCED']['maxValue'])}}</span>

                                    </span>



                                </div>

                            </div>

                            <div class=" active-cursor text-capitalize fz-12 mt-2 mb-2  ">

                                <span>{{row?.ejecutivo}}</span>
                                <span *ngIf="row?.ejecutivo && row?.agencia "> / </span>
                                <span>{{row?.agencia}}</span>

                            </div>


                            <div class="fz-12 mt-2 mb-2">


                                <div (click)="setViewsNote({user: row?.username,form:false})" *ngIf="getNoteForUsername(row?.username)" class="d-flex active-cursor  ">


                                    <div class="note active-cursor mt-2 fz-13">

                                        <div class=" active-cursor  mt-2">
                                            <div class=" text-secondary fz-12">{{getNoteForUsername(row?.username)?.create_date | date: 'dd/MM/yyyy HH:mm'}}</div>
                                            <span class=" font-weight-bold pr-2">{{getNoteForUsername(row?.username)?.ejecutive }}</span> <span>{{substring250(getNoteForUsername(row?.username)?.note)?.text}}</span>
                                            <span class="text-secondary" *ngIf="substring250(getNoteForUsername(row?.username)?.note)?.more">... ver mas</span>


                                        </div>

                                    </div>

                                </div>

                                <div (click)="$event.stopPropagation();setViewsNote({user: row?.username,form:true})" class="active-cursor text-primary fz-12 mt-1">

                                    <i class="fas fa-sticky-note"></i> Agregar Comentario
                                </div>

                            </div>
                        </div>
                    </div>


                    <!-- 
                                <div class="bd-highlight mb-2">

                                                        <span *ngIf="!row?.active && row?.razon" class="badge badge-light p-2 mt-2 ">
                                                            <i class="fas fa-archive mr-1"></i> {{row?.razon}}</span>
                                                         </div>

                                                    

                                                            <ng-container *ngIf="row?.status?.additionalType =='AC_INCOMPLETE'">

                                                                <div class="bd-highlight w-40 w-sm-100">

                                                                    <div class="alert alert-info m-0 mt-1 pt-1 pb-1" (click)="viewResponces($event,row?.solicitud)">
                                                                        Respuesta y comentarios
                                                                    </div>

                                                                </div>
                                                            </ng-container>

                                -->








                </div>

            </div>

        </ng-container>

        <div class="row content-paginator m-t-1" *ngIf="paginator">
            <akeela-paginator [paginator]="paginator" (response)="action_paginator($event)" *ngIf="!loading_table">
            </akeela-paginator>
        </div>

        <ng-container *ngIf="loading_table || loading_order">
            <div>
                <img src="./assets/public/images/loading.gif" alt="">
            </div>
        </ng-container>

        <ng-container *ngIf="!loading_table && rows?.length < 1">
            <div class="mt-2 text-secondary">
                No se encontraton solicitudes
            </div>
        </ng-container>
    </div>
</ng-container>