<!-- <akeela-template>
  <ng-template akeela-content componentName="DetailClientComponent">
    <app-detail-client></app-detail-client>
  </ng-template>
  <ng-template akeela-content componentName="ListClientsComponent">
    <app-list-clients></app-list-clients>
  </ng-template>
  <ng-template akeela-content componentName="ListAnalysisComponent">
    <app-list-account-analysis></app-list-account-analysis>
  </ng-template>
  <ng-template akeela-content componentName="PaperworkSectionComponent">
    <app-private-paperwork></app-private-paperwork>
  </ng-template>
  <ng-template akeela-content componentName="CommentsSectionComponent">
    <app-private-resume></app-private-resume>
  </ng-template>
  <ng-template akeela-content componentName="ResumenUserSectionComponent">
    <app-private-resumen></app-private-resumen>
  </ng-template>
</akeela-template> -->



<akeela-template>


    <ng-template akeela-content componentName="imgLogo">
        <div class="mt-2">
            <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
        </div>
    </ng-template>



    <ng-template akeela-content componentName="SectionShareHoldersRelated">
        <app-section-shareholders-relateds></app-section-shareholders-relateds>

    </ng-template>


    <ng-template akeela-content componentName="REPLACEcomponentFrontEndREPLACE">
        <app-section-other-legal-representative></app-section-other-legal-representative>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepShareHoldersComponent">
        <app-section-pep-shareholders></app-section-pep-shareholders>
    </ng-template>
    <ng-template akeela-content componentName="ConstitutionDataComponent">
        <app-section-constitution-data></app-section-constitution-data>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepBoardOfDirectorsComponent">
        <app-section-pep-boardofdirectors></app-section-pep-boardofdirectors>
    </ng-template>
    <ng-template akeela-content componentName="DetailClientComponent">
        <app-detail-client></app-detail-client>
    </ng-template>
    <ng-template akeela-content componentName="ListClientsComponent">
        <app-list-clients></app-list-clients>
    </ng-template>
    <ng-template akeela-content componentName="ListAgenciesComponent">
        <app-list-agencies></app-list-agencies>
    </ng-template>

    <ng-template akeela-content componentName="ListSalesRepresentativeComponent">
        <app-sales-representative></app-sales-representative>
    </ng-template>







    <ng-template akeela-content componentName="ListAnalysisComponent">
        <app-list-account-analysis></app-list-account-analysis>
    </ng-template>
    <ng-template akeela-content componentName="ListFormalitiesRelatedComponent">

        <!-- Juan
        <app-list-formalities-related></app-list-formalities-related>

          -->
    </ng-template>
    <ng-template akeela-content componentName="ListVersionsComponent">

        <!-- Juan
        <app-list-formalities-relationship></app-list-formalities-relationship>


          -->
    </ng-template>
    <ng-template akeela-content componentName="SwornSectionComponents">
        <app-private-sworn></app-private-sworn>
    </ng-template>
    <ng-template akeela-content componentName="CommentsSectionComponent">
        <app-private-resume></app-private-resume>
    </ng-template>
    <ng-template akeela-content componentName="ResumenUserSectionComponent">
        <app-private-resumen [setAction]="action" *ngIf="resumen"></app-private-resumen>
    </ng-template>
    <ng-template akeela-content componentName="PaperworkSectionComponent">
        <!-- <app-paperwork-user></app-paperwork-user> -->
    </ng-template>
    <ng-template akeela-content componentName="SectionLegalRepresentativeComponent">
        <app-section-legal-representative></app-section-legal-representative>
    </ng-template>
    <ng-template akeela-content componentName="SectionShareholdersComponent">
        <app-section-shareholders></app-section-shareholders>
    </ng-template>
    <ng-template akeela-content componentName="SectionBoardOfDirectorComponent">
        <app-section-board-of-directors></app-section-board-of-directors>
    </ng-template>
    <ng-template akeela-content componentName="SectionSuppliersComponent">
        <app-section-suppliers></app-section-suppliers>
    </ng-template>
    <ng-template akeela-content componentName="SectionCustomersComponent">
        <app-section-customers></app-section-customers>
    </ng-template>
    <ng-template akeela-content componentName="SectionRelatedCompanyComponent">
        <app-section-related-company></app-section-related-company>
    </ng-template>
    <ng-template akeela-content componentName="SectionBankAccountInstitutionComponent">
        <app-section-bank-account-institution></app-section-bank-account-institution>
    </ng-template>
    <ng-template akeela-content componentName="SectionBankAccountOtherInstitutionComponent">
        <app-section-bank-account-other-institution></app-section-bank-account-other-institution>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepComponent">
        <app-section-pep></app-section-pep>
    </ng-template>
    <ng-template akeela-content componentName="UploadSectionComponent">
        <div class="col-12">

            <app-requirements-response [isActiveResume]="false"></app-requirements-response>
        </div>
    </ng-template>
    <ng-template akeela-content componentName="TabComponent">
        <app-resumen-header (action)="setAction($event)" (change)="update($event)"></app-resumen-header>
        <!-- Juan


         
          <app-tabs></app-tabs>
 -->

        <app-alert></app-alert>
    </ng-template>
    <ng-template akeela-content componentName="SpecificCommentComponent">
        <app-incompleted-comments></app-incompleted-comments>
    </ng-template>
    <ng-template akeela-content componentName="PreviuslyRevisionsComponent">

        <!-- Juan
        <app-previusly-revisions></app-previusly-revisions>
        -->

    </ng-template>



    <ng-template akeela-content componentName="commercialReferencesSectionComponent">
        <app-section-commercial-references></app-section-commercial-references>
    </ng-template>

    <ng-template akeela-content componentName="SectionNewTransactionClientHeader">
        <app-section-newtransaction-header></app-section-newtransaction-header>
    </ng-template>


    <!-- PN -->

    <ng-template akeela-content componentName="legalRepresentativeSectionComponent">
        <app-section-legal-representative-pn></app-section-legal-representative-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionPNPepComponent">
        <app-section-pep-pn></app-section-pep-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionPNPepAssociationComponent">
        <app-section-pep-pn-association></app-section-pep-pn-association>
    </ng-template>

    <ng-template akeela-content componentName="customersPNSectionComponent">
        <app-section-customers-pn></app-section-customers-pn>
    </ng-template>

    <ng-template akeela-content componentName="supplierPNSectionComponent">
        <app-section-suppliers-pn> </app-section-suppliers-pn>
    </ng-template>

    <ng-template akeela-content componentName="personalReferencesPNSectionComponent">
        <app-section-personal-references-pn></app-section-personal-references-pn>
    </ng-template>

    <ng-template akeela-content componentName="workspacesComponent">
        <app-workspaces></app-workspaces>
    </ng-template>
    <ng-template akeela-content componentName="SectionFiscalDirections">
        <div class="col-12">
            <app-section-fiscal-directions></app-section-fiscal-directions>
        </div>
    </ng-template>

    <ng-template akeela-content componentName="ComponetRightSectiosStep">
        <app-private-min-resume></app-private-min-resume>
    </ng-template>


    <ng-template akeela-content componentName="sectionsResponseHeaderComponent">
        <div>
            <app-resumen-sections-header></app-resumen-sections-header>

        </div>
    </ng-template>


    <ng-template akeela-content componentName="sectionsResponseFooterComponent">
        <app-resumen-sections-footer></app-resumen-sections-footer>
    </ng-template>

    <ng-template akeela-content componentName="DashboardComponent">
        <app-akeela-dashboard></app-akeela-dashboard>
    </ng-template>


    <ng-template akeela-content componentName="SendClientSectionComponent">

        <app-send-client-section></app-send-client-section>

    </ng-template>

    <ng-template akeela-content componentName="INVESTOR_PROFILE_COMPONENT">
        <app-investor-profile></app-investor-profile>
    </ng-template>

</akeela-template>