<akeela-user-verification *ngIf="mdActve" [selected]="init_verif" [username]="userverif" (change)="setVerificationData($event)">
</akeela-user-verification>

<app-new-transaction *ngIf="mdActve" [selected]="newTransaction" (change)="setNewTransaction($event)"></app-new-transaction>

<akeela-meet *ngIf="mdActve" [selected]="init_meet" [username]="userMeet" (change)="setMeetData($event)">
</akeela-meet>

<akeela-meet *ngIf="init_meet && mdActve" [selected]="init_meet" [username]="userMeet" (change)="setMeetData($event)">
</akeela-meet>

<app-template>
    <ng-template app-content componentName="mainContentModalBotton">
        <div class="capa-menu-2" *ngIf="buttomModal && mdActve" (click)="changes()"> </div>

        <div *ngIf="mdActve" [ngClass]="{'d-none':animate_init_bottom,'animate__slideInUp':buttomModal,animate__slideOutDown:!buttomModal } " class="  animate__animated animate__faster ak-modal-botoom ak-modal-botoom2  p-2 ">
            <div class="body pb-4 pt-2 " style="overflow:auto;">
                <div *ngIf="clientDetail" class="container text-center">
                    <div class=" w-100 text-center ">

                        <div *ngIf="!dataAndSelfi?.description" class=" DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--client" role="img">
                            <div *ngIf="!isLegal()" (click)="setOpenVerif($event)" class="huella2 active-cursor">
                                <i class="fas fa-fingerprint" [ngClass]="{'text-success': verifiedIdentification,'text-primary': !verifiedIdentification && compleatVerifi  }"></i>
                            </div>
                            <i [ngClass]="{'fa-building': isLegal(),'fa-user': !isLegal()}" class="fas "></i>

                        </div>

                        <div (click)="show_file(getDocumentClient('SELFIE'))" style="border: 1px solid #ddd; background: url({{filePreview}}) no-repeat center" class=" animate__animated animate__fadeIn content-avata-img " *ngIf="dataAndSelfi?.description">
                            <div (click)="setOpenVerif($event)" class="huella active-cursor">
                                <i class="fas fa-fingerprint" [ngClass]="{'text-success': verifiedIdentification,'text-primary': !verifiedIdentification && compleatVerifi  }"></i>
                            </div>
                        </div>
                        <div class=" mt-2 font-weight-bold fz-16" *ngIf="isLegal()"> {{clientDetail?.adminOrganization?.legalName }} </div>
                        <div *ngIf="clientDetail?.person?.givenName " [ngClass]="{'font-weight-bold fz-16  mt-2  ': !isLegal(),'fz-14': isLegal()}" class="text-capitalize"> {{ clientDetail?.person?.givenName + ' ' + clientDetail?.person?.familyName }} </div>

                        <div class="  font-weight-normal fz-14" *ngIf="!isLegal()">{{dataInformation?.identity}}</div>
                        <div class="  font-weight-normal fz-14" *ngIf="isLegal()">{{dataInformation?.taxID}}</div>


                        <div class="mt-2 mb-2 text-secondary  fz-16">{{clientDetail?.identifier}}</div>

                        <div *ngIf="clientDetail?.createDate" class="text-secondary  fz-14 mt-2 ">Registrado {{clientDetail?.createDate | date: 'd MMM, y h:mm a'}}</div>

                        <div class=" font-weight-bold  fz-12 mt-3 " *ngIf="clientDetail?.dateOfThing?.startDate">Últ. sesión {{clientDetail?.dateOfThing?.startDate | date: 'd MMM, y h:mm a'}}</div>

                        <div class="row fz-12">
                            <div (click)="setOpenVerif($event)" *ngIf="!verifiedIdentification && compleatVerifi " class="alert alert-secondary mt-3 mb-2 p-2 col-12 offset-md-3 col-md-6 " role="alert">

                                Verificación de identidad pendiente por revisar
                                <i class="text-primary float-right fas fa-angle-right fz-16"></i>

                            </div>


                            <div [ngClass]="{'alert alert-secondary':dataInfoClientLA || loadingdataInfoClientLA || (!dataInfoClientLA  && !transactionCompleat && !(dataInfoClientLA  && transactionCompleat)),' alert alert-warning':!dataInfoClientLA && !loadingdataInfoClientLA}"
                                *ngIf="verificationLA" class="font-weight-normal alert alert-secondary mt-3 mb-2 p-3 col-12 offset-md-2 col-md-8 text-left fz-14" role="alert">

                                <div *ngIf="loadingdataInfoClientLA" class="text-center w-100">
                                    cargando ...
                                    <div>

                                        <img style="width:20px ;" src="./assets/public/images/loading.gif " alt=" ">
                                    </div>
                                </div>

                                <div *ngIf="!loadingdataInfoClientLA">
                                    <!-- 
                                    dataInfoClientLA : {{dataInfoClientLA}} <br>
                                    getdataInfoClientLAError : {{getdataInfoClientLAError}} <br>
                                    transactionCompleat : {{transactionCompleat}} <br>
                                    isGetServceLA : {{isGetServceLA}} <br> -->




                                    <div *ngIf="dataInfoClientLA  && !getdataInfoClientLAError">
                                        Registrado en <b>LA Sistemas</b> como: {{dataInfoClientLA?.givenName}} {{dataInfoClientLA?.familyName}}, C.I. {{dataInfoClientLA?.identity}} Contacto 1: {{dataInfoClientLA?.telephone}}, Contacto 2: {{dataInfoClientLA?.contactPoint?.telephone}},
                                        Email: {{dataInfoClientLA?.contactPoint?.email}}, Código en LA Sistemas: {{dataInfoClientLA?.externalCode}}, Fecha de creación: {{dataInfoClientLA?.dateOfThing?.startDate | date: 'd MMM, y'}}<span *ngIf="false">
                                         ,Fecha de última modificación:{{dataInfoClientLA?.dateOfThing?.modifiedDate | date: 'd MMM, y'}}
                                        </span>

                                    </div>


                                    <div class="text-secondary" *ngIf="!dataInfoClientLA && !getdataInfoClientLAError && !transactionCompleat">

                                        <div *ngIf="isGetServceLA">
                                            Cliente no se encuentra en <b>LA Sistemas</b>
                                        </div>
                                        <div *ngIf="!isGetServceLA">
                                            Consultar el cliente en <b>LA Sistemas</b>

                                            <button class="mt-1 mt-md-0 float-md-right btn btn-secondary ml-md-2" (click)="getExistClientLA()">
                                            Consultar
                                            </button>
                                        </div>

                                    </div>

                                    <div class="text-secondary fz-14 mt-md-1" *ngIf=" !dataInfoClientLA  && !getdataInfoClientLAError  && transactionCompleat">
                                        <div *ngIf="isGetServceLA">
                                            Crear el cliente en <b>LA Sistemas</b>

                                            <button class="mt-1 mt-md-0 float-md-right btn btn-secondary ml-md-2" (click)="crearPersonaNaturalLA()">
                                        Crear
                                        </button>

                                        </div>

                                        <div *ngIf="!isGetServceLA">
                                            Consultar el cliente en <b>LA Sistemas</b>

                                            <button class="mt-1 mt-md-0 float-md-right btn btn-secondary ml-md-2" (click)="getExistClientLA()">
                                        Consultar
                                        </button>
                                        </div>
                                    </div>


                                    <div *ngIf="getdataInfoClientLAError " class="text-secondary fz-14 mt-md-1">
                                        Error al consultar el cliente en <b>LA Sistemas</b>

                                        <button class="mt-1 mt-md-0 float-md-right btn btn-secondary ml-md-2" (click)="getDataInfoClientLA(dataInformation?.identity)">
                                          Refrescar
                                        </button>
                                    </div>


                                </div>
                                <!--

                                <pre *ngIf="!loadingdataInfoClientLA">{{ dataInfoClientLA | json }}</pre>
 -->

                            </div>
                            <div [ngClass]="{'alert alert-secondary':dataInfoClientAKB || loadingdataInfoClientAKB || (!dataInfoClientAKB  && !transactionCompleat && !(dataInfoClientAKB  && transactionCompleat)),' alert alert-warning':!dataInfoClientAKB && !loadingdataInfoClientAKB}"
                                *ngIf="verificationAKB && clientDetail?.personMarketType?.audienceType==='NATURAL'" class="font-weight-normal bg-white alert alert-secondary mt-3 mb-2 p-3 col-12 offset-md-2 col-md-8 text-left fz-14" role="alert">


                                <div *ngIf="loadingdataInfoClientAKB" class="text-center w-100">
                                    cargando ...
                                    <div>

                                        <img style="width:20px ;" src="./assets/public/images/loading.gif " alt=" ">
                                    </div>
                                </div>

                                <div *ngIf="!loadingdataInfoClientAKB">
                                    <div *ngIf="dataInfoClientAKB  && !getdataInfoClientAKBError">
                                        Registrado en <b>Akbrokerage</b> como: {{dataInfoClientAKB?.givenName}} {{dataInfoClientAKB?.familyName}},
                                        <span> C.I.</span> {{dataInfoClientAKB?.identity}}
                                        <span *ngIf="dataInfoClientAKB?.telephone"> Contacto : </span> {{dataInfoClientAKB?.telephone}},
                                        <span *ngIf="dataInfoClientAKB?.contactPoint?.telephone"> Contacto : </span> {{dataInfoClientAKB?.contactPoint?.telephone}}, Email: {{dataInfoClientAKB?.email}}, Código en Akbrokerage: {{dataInfoClientAKB?.externalCode}},
                                        Fecha de creación: {{dataInfoClientAKB?.externalDate | date: 'd MMM, y'}}


                                    </div>
                                    <div class="text-secondary" *ngIf="!dataInfoClientAKB && !getdataInfoClientAKBError && !transactionCompleat">
                                        Cliente no se encuentra en <b>Akbrokerage</b>
                                    </div>


                                    <div class="text-secondary fz-14 mt-md-1" *ngIf="!dataInfoClientAKB  && transactionCompleat && !getdataInfoClientAKBError">
                                        Crear en cliente en <b>Akbrokerage</b>

                                        <button class="mt-1 mt-md-0 float-md-right btn btn-secondary ml-md-2" (click)="crearPersonaNaturalAKB()">
                                        Crear
                                        </button>
                                    </div>


                                    <div *ngIf="getdataInfoClientAKBError " class="text-secondary fz-14 mt-md-1">
                                        Error al consultar el cliente en <b>Akbrokerage</b>

                                        <button class="mt-1 mt-md-0 float-md-right btn btn-secondary ml-md-2" (click)="getDataInfoClientAKB(dataInformation?.identity)">
                                          Refrescar
                                        </button>
                                    </div>


                                </div>
                                <!--

                                <pre *ngIf="!loadingdataInfoClientAKB">{{ dataInfoClientAKB | json }}</pre>
 -->

                            </div>

                        </div>

                        <!-- <div class="row" (click)="changeStatusFacialRecognition(clientDetail?.person?.facialRecognitionActive)" *ngIf="clientDetail && !verifiedIdentification && !compleatVerifi ">

                            <div class="col-12 col-md-6 offset-md-3 text-right">
                                <i class="fas fa-user-lock fa-2x active-cursor" title="Desactivar Reconocimiento Facial" style="color:green" *ngIf="clientDetail?.person?.facialRecognitionActive===true || clientDetail?.person?.facialRecognitionActive==='true'"></i>
                                <i class="fas fa-user-lock fa-2x active-cursor" title="Activar Reconocimiento Facial" *ngIf="clientDetail?.person?.facialRecognitionActive===false || clientDetail?.person?.facialRecognitionActive==='false'"></i>
                            </div>
                        </div> -->

                    </div>

                    <div class="mt-4">

                        <div class=" w-100 text-center ">
                            <div class="d-flex  justify-content-top flex-wrap">
                                <div class="w-100">

                                    <ul class="scrollmenu pl-0 mb-0 ">
                                        <li class="d-inline">
                                            <div (click)="setView(0)" class="item-rd active-cursor ">
                                                <div [ngClass]="{'border-verification-primary': animate_contact,'border-verification-secondary': !animate_contact}" class=" DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                    <i class="fas fa-address-card"></i>
                                                </div>
                                                <div class=" mt-2 text-secondary fz-14">Contacto</div>
                                            </div>

                                            <div (click)="setDataClient()" class="item-rd active-cursor ">
                                                <div [ngClass]="{'border-verification-primary': init_clientData,'border-verification-secondary': !init_clientData}" class=" DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                    <i [ngClass]="{'fa-building': isLegal(),'fa-user': !isLegal()}" class="fas"></i>
                                                </div>
                                                <!--
                                                <pre>{{ dataInformation | json }}</pre>
 -->
                                                <div class=" mt-2 text-secondary fz-14">Datos de cliente<span *ngIf="!dataInformation?.completeBriefcase" class="font-weight-bold fz-12"> ({{indicatorsProgres?.minValue }} / {{indicatorsProgres?.maxValue}})</span>

                                                    <span *ngIf="dataInformation?.completeBriefcase" class="font-weight-bold fz-12">       
                                                           <i [ngClass]="{'text-secondary': !dataInformation?.verifiedBriefcase,'text-success': dataInformation?.verifiedBriefcase}" class="fas fa-check ml-1 text-success"></i>
                                                    </span>
                                                </div>


                                            </div>

                                            <div (click)="setView(2)" class="item-rd ">
                                                <div [ngClass]="{'border-verification-primary': animate_solic,'border-verification-secondary': !animate_solic}" class="DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                    <i class="fas fa-layer-group"></i>
                                                </div>
                                                <div class=" mt-2 text-secondary fz-14"> Solicitudes ( <span class="font-weight-bold fz-12">{{rows?.length}}</span> )</div>
                                            </div>

                                            <div (click)="setOpenMeet()" class="item-rd ">
                                                <div class="DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                    <i class="fas fa-video"></i>
                                                </div>
                                                <div class=" mt-2 text-secondary fz-14">Reuniones</div>
                                            </div>

                                            <div (click)="setViewsNote({user: client,form:false})" class="item-rd ">
                                                <div class="DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                    <i class="fas fa-sticky-note"></i>
                                                </div>
                                                <div class=" mt-2 text-secondary fz-14">Notas</div>
                                            </div>

                                            <div *ngIf="cvv" (click)="$event.stopPropagation();setViewsCVV({user: client,form:false})" class="item-rd ">
                                                <i *ngIf="dataInformation?.dateRegisteredCvv " class="fas fa-check text-success check-true"></i>

                                                <div class="DomainUserAvatar-avatar text-secondary AvatarPhoto AvatarPhoto--opt  active-cursor " role="img">
                                                    <i class="fas fa-archive"></i>
                                                </div>
                                                <div class=" mt-2 text-secondary fz-14">CVV</div>

                                            </div>



                                        </li>
                                    </ul>


                                </div>

                            </div>
                        </div>
                    </div>


                    <ng-container *ngIf="clientDetail &&  !loading ">



                        <div *ngIf="animate_verifi" class="row mt-4 ">



                            <div class="col-12 col-md-6 offset-md-3 ">
                                <button [disabled]="!compleatVerifi " *ngIf="!optVerification && !verifiedIdentification" class="btn btn-outline-primary mt-2 mb-2 " (click)="optVerification = true">
                                    Responder verificación
                                </button>


                                <div *ngIf="optVerification && !verifiedIdentification" class="text-right mt-2 mb-2">
                                    <i (click)="optVerification = !optVerification" class="  active-cursor  text-secondary fas fa-times " style="font-size: 1.2rem;"></i>

                                </div>

                                <div *ngIf="verifiedIdentification" class="pt-2 pb-3">
                                    <span class="alert alert-success ">Verificación de identidad aprobada</span>

                                </div>

                                <ng-container *ngIf="optVerification">
                                    <div class="animate__animated animate__fadeIn">

                                        <button (click)="verificarClient()" class="btn btn-primary btn-block mt-2 mb-3 ">
                                            Verificar
                                        </button>
                                        <button (click)="resetVerification()" class="btn btn-primary btn-block mt-2 mb-3 ">
                                            Devolver
                                        </button>
                                        <button disabled class="btn btn-secondary btn-block mt-2 mb-3 ">
                                            Copy Link
                                        </button>
                                        <button class="btn btn-outline-danger btn-block mt-4 mb-3 ">
                                            Rechazar
                                        </button>

                                    </div>

                                </ng-container>

                                <ng-container *ngIf="!optVerification ">
                                    <div class=" text-left mt-4 animate__animated animate__fadeIn">


                                        <div class="pl-2 text-secondary font-weight-bold fz-16 mt-2 mb-2">Verificación de identidadsss</div>
                                        <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                            <div class="w-100">

                                                <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                    <div class="w-50   text-capitalize">
                                                        <div class="font-weight-bold  fz-14  ">Nacionalidad</div>
                                                        <div class="text-secondary  fz-14  ">{{dataInformation?.nationality?.nationality}}</div>

                                                    </div>
                                                    <div class="w-50  text-right" *ngIf="verifiedIdentification && clientDetail?.createDate">
                                                        <div>
                                                            <i class="fas fa-check text-success"></i>
                                                        </div>

                                                        <div class="text-secondary  fz-14 ">{{dataInformation?.birthDate | date: 'd MMM, y h:mm a'}}</div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w-100 mt-2">
                                                <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                    <div class="w-50   text-capitalize ">
                                                        <div class="font-weight-bold  fz-14  ">Número de Documento</div>
                                                        <div class="text-secondary  fz-14  ">{{dataInformation?.identity}}</div>

                                                    </div>
                                                    <div class="w-50  text-right" *ngIf="verifiedIdentification && clientDetail?.mailConfirmationDate">
                                                        <div>
                                                            <i class="fas fa-check text-success"></i>
                                                        </div>
                                                        <div class="text-secondary  fz-14 ">{{dataInformation?.birthDate | date: 'd MMM, y h:mm a'}}</div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="w-100 mt-2">
                                                <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                    <div class="w-100   text-capitalize">
                                                        <div class="font-weight-bold  fz-14  ">Fecha de Nacimiento</div>
                                                        <div class="text-secondary  fz-14  ">{{dataInformation?.birthDate | date: 'd MMM, y':'UTC'}}</div>

                                                    </div>
                                                    <div class="w-50  text-right">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pl-2 text-secondary font-weight-bold fz-16 mt-3 mb-2">Documentos de Identificación</div>


                                        <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                            <div class="w-100">

                                                <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                    <div class="w-90   text-capitalize">
                                                        <div class="font-weight-bold  fz-14  pb-2">Documento de ID personal</div>

                                                    </div>
                                                    <div class="w-10  text-right" *ngIf="clientDetail?.createDate">
                                                        <div *ngIf="getDocumentClient('DOC_IDENTITY')">
                                                            <i class="fas fa-check text-success" [ngClass]="{'text-success': verifiedIdentification }"></i>
                                                        </div>

                                                    </div>
                                                    <div class="w-100">
                                                        <div class="container-image">

                                                            <div class="image-column text-center br-10" (click)="show_file(getDocumentClient('DOC_IDENTITY'))" *ngIf="getDocumentClient('DOC_IDENTITY')!==null">
                                                                <app-preview-img [identifier]="getDocumentClient('DOC_IDENTITY')" (response)="getResponsePreview($event)"></app-preview-img>
                                                                <div class="overlay">
                                                                    <div class="text active-cursor">
                                                                        <i class="fas fa-eye"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="image-column text-center" *ngIf="!getDocumentClient('DOC_IDENTITY')">

                                                                <div>
                                                                    <i class="far fa-clock fa-2x"></i>
                                                                    <br>
                                                                    <div class="font-weight-bold text-black">Pendiente</div>
                                                                </div>

                                                            </div>




                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="w-100 mt-2">

                                                <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                    <div class="w-90   text-capitalize">
                                                        <div class="font-weight-bold  fz-14  pb-2">Selfie</div>

                                                    </div>

                                                    <div class="w-10  text-right" *ngIf="clientDetail?.createDate">
                                                        <div *ngIf="getDocumentClient('SELFIE')">
                                                            <i class="fas fa-check text-success" [ngClass]="{'text-success': verifiedIdentification }"></i>
                                                        </div>

                                                    </div>
                                                    <div class="w-100">
                                                        <div class="container-image">

                                                            <div class="image-column text-center br-10" (click)="show_file(getDocumentClient('SELFIE'))" *ngIf="getDocumentClient('SELFIE')!==null">
                                                                <app-preview-img [identifier]="getDocumentClient('SELFIE')" (response)="getResponsePreview($event)"></app-preview-img>
                                                                <div class="overlay">
                                                                    <div class="text active-cursor">
                                                                        <i class="fas fa-eye"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="image-column text-center" *ngIf="!getDocumentClient('SELFIE')">

                                                                <div>
                                                                    <i class="far fa-clock fa-2x"></i>.
                                                                    <br>
                                                                    <div class="font-weight-bold text-black">Pendiente</div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="w-100 mt-2">

                                                <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                    <div class="w-90   text-capitalize">
                                                        <div class="font-weight-bold  fz-14  pb-2">Selfie + ID</div>

                                                    </div>
                                                    <div class="w-10  text-right" *ngIf="clientDetail?.createDate">
                                                        <div *ngIf="getDocumentClient('SELFIE_DOC_IDENTITY')">
                                                            <i class="fas fa-check text-success" [ngClass]="{'text-success': verifiedIdentification }"></i>
                                                        </div>

                                                    </div>
                                                    <div class="w-100">

                                                        <div class="container-image">

                                                            <div class="image-column text-center br-10" (click)="show_file(getDocumentClient('SELFIE_DOC_IDENTITY'))" *ngIf="getDocumentClient('SELFIE_DOC_IDENTITY')!==null">
                                                                <app-preview-img [identifier]="getDocumentClient('SELFIE_DOC_IDENTITY')" (response)="getResponsePreview($event)"></app-preview-img>
                                                                <div class="overlay">
                                                                    <div class="text active-cursor">
                                                                        <i class="fas fa-eye"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="image-column text-center" *ngIf="!getDocumentClient('SELFIE_DOC_IDENTITY')">

                                                                <div>
                                                                    <i class="far fa-clock fa-2x"></i>.
                                                                    <br>
                                                                    <div class="font-weight-bold text-black">Pendiente</div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </ng-container>


                            </div>

                        </div>

                        <div *ngIf="animate_contact" class="row mt-4 animate__animated animate__fadeIn">


                            <div class="col-12 col-md-8 offset-md-2">
                                <div class=" text-left">

                                    <div class="pl-2 text-secondary font-weight-bold fz-16 mt-2 mb-2">Información de contacto</div>
                                    <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                        <div class="w-100">

                                            <div *ngIf="!updatePhone" class="d-flex bd-highlight  flex-wrap  mb-3">
                                                <div class="w-50   text-capitalize">
                                                    <div class="font-weight-bold  fz-14 mb-2 ">Teléfono</div>
                                                    <div *ngIf="clientDetail?.person?.telephone" class="text-secondary  fz-14 mb-3 ">
                                                        {{clientDetail?.person?.telephone}}
                                                    </div>
                                                    <div *ngIf="clientDetail?.person?.otherPhone" class="text-secondary  fz-14 mb-1 ">

                                                        <a (click)="gotoWhatsapp(clientDetail?.person?.otherPhone)" href="javascript:void(0) " class="text-primary ">

                                                            <span (click)="setEditPhone(clientDetail?.person?.otherPhone)" class="active-cursor text-primary mr-1">
                                                                <i class="fas fa-pencil-alt"></i>
                                                            </span> {{clientDetail?.person?.otherPhone}}
                                                            <i class="fab fa-whatsapp pl-1"></i>
                                                        </a>

                                                    </div>

                                                </div>
                                                <!-- 
                                                <div class="w-50  text-right" *ngIf="clientDetail?.createDate">
                                                  

                                                </div>
                                            -->
                                            </div>

                                            <div *ngIf="updatePhone" class="d-flex bd-highlight  flex-wrap  mb-3">
                                                <div class="w-100   text-capitalize ">
                                                    <div class="font-weight-bold  fz-14  pb-2 ">Phone</div>

                                                    <div class="input-group edit-btn-input">

                                                        <input (keyup)="validatePhone(clientDetail?.person?.otherPhone,newPhone)" [(ngModel)]="newPhone" type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                        <div class="input-group-append">
                                                            <button (click)="submitPhone()" [disabled]="errorPhone || clientDetail?.person?.otherPhone == newPhone" class="btn btn-outline-primary" type="button">Guardar</button>
                                                        </div>
                                                        <span (click)="updatePhone = false" class="active-cursor text-danger mt-2 fz-16  ml-2">
                                                            <i class="fas fa-times"></i>
                                                        </span>

                                                    </div>
                                                    <div *ngIf="errorPhone" class="fz-12 ml-2 mt-1 text-danger">
                                                        {{errorPhone}}
                                                    </div>
                                                    <div *ngIf="loadingPhone" class="fz-12 ml-2 mt-1 text-danger">
                                                        Verificando...
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="w-100 mt-2">
                                            <div *ngIf="!updateEmail" class="d-flex bd-highlight  flex-wrap  mb-3">
                                                <div class="w-50   text-capitalize ">
                                                    <div class="font-weight-bold  fz-14  pb-2">Email</div>
                                                    <div class="text-secondary  fz-14  text-lowercase">
                                                        <span (click)="setEditEmail(clientDetail?.person?.email)" class="active-cursor text-primary mr-1">
                                                            <i class="fas fa-pencil-alt"></i>
                                                        </span> {{clientDetail?.person?.email}}

                                                    </div>
                                                </div>
                                                <div class="w-50  text-right" *ngIf="clientDetail?.mailConfirmationDate">
                                                    <div class="mb-2">
                                                        <i class="fas fa-check text-success"></i>
                                                    </div>
                                                    <div class="text-secondary  fz-14 ">{{clientDetail?.mailConfirmationDate | date: 'd MMM, y h:mm a'}}</div>

                                                </div>
                                            </div>
                                            <div *ngIf="updateEmail" class="d-flex bd-highlight  flex-wrap  mb-3">
                                                <div class="w-100   text-capitalize ">
                                                    <div class="font-weight-bold  fz-14  pb-2 ">Email</div>

                                                    <div class="input-group edit-btn-input">

                                                        <input (keyup)="validateEmail(clientDetail?.person?.email,newEmail)" [(ngModel)]="newEmail" type="text" class="form-control text-lowercase" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                        <div class="input-group-append">
                                                            <button (click)="submitEmail()" [disabled]="errorEmail || clientDetail?.person?.email == newEmail" class="btn btn-outline-primary" type="button">Guardar</button>
                                                        </div>
                                                        <span (click)="updateEmail = false" class="active-cursor text-danger mt-2 fz-16  ml-2">
                                                            <i class="fas fa-times"></i>
                                                        </span>

                                                    </div>
                                                    <div *ngIf="errorEmail" class="fz-12 ml-2 mt-1 text-danger">
                                                        {{errorEmail}}
                                                    </div>
                                                    <div *ngIf="loadingEmail" class="fz-12 ml-2 mt-1 text-danger">
                                                        Verificando...
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w-100 mt-2">

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50   text-capitalize">
                                                    <div class="font-weight-bold  fz-14  ">Estatus</div>
                                                    <div class="text-secondary  fz-14  ">{{ clientDetail.status==='ACTIVE'? 'Activo':'Ináctivo'}}</div>

                                                </div>
                                                <div class="w-50  text-right" *ngIf="clientDetail?.createDate">
                                                    <div class="font-weight-bold  fz-14  ">Tipo</div>
                                                    <div class="text-secondary  fz-14  ">
                                                        <span [innerHTML]="clientDetail.personMarketType.identifier==='NATURAL'? ('@i18n-quote-option-list-applicantType-option-natural' | i18n): ('@i18n-quote-option-list-applicantType-option-legal' | i18n)"></span>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <div *ngIf="env.theme.images == 'CARONI'" class="w-100 mt-2">

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50   text-capitalize">
                                                    <div class="font-weight-bold  fz-14  ">Cliente Caroní</div>
                                                    <div class="text-secondary  fz-14  ">{{dataInformation?.customer?.banked? "Si":"No" }}</div>

                                                </div>
                                                <div class="w-50  text-right" *ngIf="clientDetail?.createDate">
                                                    <div class="font-weight-bold  fz-14  ">Cliente en ClickCaroní</div>
                                                    <div class="text-secondary  fz-14  ">
                                                        {{dataInformation?.customer?.internetBanking? "Si":"No" }}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <div class="w-100 mt-2">

                                            <div class="d-flex bd-highlight  flex-wrap  mb-2">
                                                <div class="w-50   text-capitalize">
                                                    <div class="font-weight-bold  fz-14  ">Ejecutivo</div>
                                                    <div class="text-secondary  fz-14  ">{{ clientDetail?.salesManagerUser?.identifier}}</div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>




                        <div *ngIf="animate_solic" class="row mt-4 animate__animated animate__fadeIn">


                            <div class="col-12 col-md-8 offset-md-2">
                                <div class=" text-left">

                                    <div class="pl-2 text-secondary font-weight-bold fz-16 ">Solicitudes</div>
                                    <div class="p-2 fz-14 d-flex bd-highlight  list-flex flex-wrap text-left size-caja-dopd">

                                        <button *ngIf="((env.theme.images != 'MC') || (env.theme.images == 'MC' && rows?.length < 1) ) && !isAnalist" (click)="newTransaction = true " class="btn btn-outline-primary">
                                            Nueva Solicitud
                                        </button>

                                        <div class="w-100">

                                            <div class="mt-3" *ngIf="rows?.length < 1">
                                                No se encontraron solicitudes
                                            </div>


                                            <ng-container *ngFor="let row of rows ; let i = index">
                                                <div class="card mt-4 mb-2 ak-shadow-box " *ngIf="i < 100">
                                                    <div class="card-body p-3 ">

                                                        <div class="d-flex bd-highlight  list-flex flex-wrap">
                                                            <div class="bd-highlight">
                                                                <div>
                                                                    <b>{{row?.plan?.name}}</b> /
                                                                    <a (click)="setTransactionView(row.solicitud);setView(3)" class="text-primary pl-1 fz-14" href="javascript:void(0)">
                                                                        {{row.solicitud}}<i title="Ver Solicitud" class="fas fa-eye pl-1"></i>
                                                                   </a>

                                                                </div>

                                                                <div [innerHTML]="(row?.plan?.shortDescription | i18n)" class="text-secondary fz-14 mb-3"></div>



                                                            </div>


                                                        </div>

                                                        <div class="d-flex  bd-highlight" *ngIf="row?.status?.additionalType =='PI_IN_PROCESS' && ifIndicatorProgresItem(row?.transactionNumber,row?.workflow)">
                                                            <div class="bd-highlight ">
                                                                <span class="small text-secondary "> Solicitud  {{(indicatorsProgresRsp[row?.transactionNumber][row?.workflow]?.minValue+'/'+indicatorsProgresRsp[row?.transactionNumber][row?.workflow]?.maxValue)}}</span>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex bd-highlight pb-2 " *ngIf="row?.status?.additionalType=='PI_IN_PROCESS' && ifIndicatorProgresItem(row?.transactionNumber,row?.workflow)">
                                                            <div class="bd-highlight w-100 ">
                                                                <ng-container>
                                                                    <div class=" d-flex bd-highlight w-70 w-sm-100 ">
                                                                        <div class=" w-5 ">
                                                                            <i class="fas fa-align-left text-secondary "></i>
                                                                        </div>
                                                                        <div class="line-2 bar w-80 pt-2 " style="min-height: 20px ">
                                                                            <akeela-graphics [percent]="indicatorsProgresRsp[row?.transactionNumber][row?.workflow]?.percent " [type]=" 'line' ">
                                                                            </akeela-graphics>
                                                                        </div>

                                                                        <div class=" w-15 text-right ">
                                                                            <span class="pl-2 ">{{indicatorsProgresRsp[row?.transactionNumber][row?.workflow]?.percent}}%</span> </div>

                                                                    </div>
                                                                </ng-container>

                                                            </div>

                                                        </div>


                                                        <div class="d-flex bd-highlight " *ngIf="row?.status?.additionalType=='PI_IN_PROCESS'  && indicatorsProgresRsp[row?.transactionNumber]['DOC_SUPPORT'] ">
                                                            <div class="bd-highlight ">
                                                                <span class="small text-secondary ">Recaudos {{(indicatorsProgresRsp[row?.transactionNumber]['DOC_SUPPORT']['minValue']+'/'+indicatorsProgresRsp[row?.transactionNumber]['DOC_SUPPORT']['maxValue'])}}</span>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex bd-highlight pb-2 " *ngIf="row?.status?.additionalType=='PI_IN_PROCESS' && ifIndicatorProgresItem(row?.transactionNumber,'DOC_SUPPORT')">
                                                            <div class="bd-highlight w-100 ">
                                                                <ng-container>
                                                                    <div class=" d-flex bd-highlight w-70 w-sm-100 ">
                                                                        <div class=" w-5 ">
                                                                            <i class="fas fa-align-left text-secondary "></i>
                                                                        </div>
                                                                        <div class="line-2 bar w-80 pt-2 " style="min-height: 20px ">
                                                                            <akeela-graphics [percent]="indicatorsProgresRsp[row?.transactionNumber]['DOC_SUPPORT']['percent']" [type]=" 'line' ">
                                                                            </akeela-graphics>
                                                                        </div>

                                                                        <div class=" w-15 text-right ">
                                                                            <span class="pl-2 ">{{indicatorsProgresRsp[row?.transactionNumber]['DOC_SUPPORT']['percent']}}%</span>
                                                                        </div>

                                                                    </div>
                                                                </ng-container>

                                                            </div>

                                                        </div>

                                                        <ng-container *ngIf="row?.status?.additionalType=='AC_RECEIVED' || row?.status?.additionalType=='AC_PENDING' ">
                                                            <div class="bd-highlight w-40 w-sm-100 ">
                                                                <button class="btn btn-outline-secondary btn-pdf " (click)="getPreview(row?.solicitud, row?.audience) ">
                                                                    <i class="far fa-file-pdf mr-1 text-danger "></i>
                                                                    <span> Ficha</span>
                                                                </button>
                                                            </div>
                                                        </ng-container>


                                                        <ng-container *ngIf="row?.status?.additionalType !='PI_IN_PROCESS' && row?.status?.additionalType !='AC_PENDING'">

                                                            <hr class="st-2 mt-3">

                                                            <inner-responses [transaction]="row.solicitud"></inner-responses>

                                                        </ng-container>


                                                        <hr class="st-2 mt-3">

                                                        <div class="d-flex flex-wrap pb-2 mt-2">
                                                            <div class="bd-highlight mb-2  w-40 w-sm-100 ">


                                                                <span [innerHTML]="(row?.status2 | i18n)" class="mt-1  badge  badge-secondary badge-secondary-2 "></span>
                                                            </div>


                                                            <div class="bd-highlight ml-md-auto w-60 w-sm-100 text-md-right ">
                                                                <div class=" fz-12 " [title]="row.fecha">
                                                                    Creada <span class="text-secondary" [innerHTML]="row.fecha"></span>
                                                                </div>
                                                                <div class=" fz-12 " [title]="row.fecha">
                                                                    Estatus <span class="text-secondary" [innerHTML]="row.fechaInic"></span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="loading ">
                        <div>
                            <img src="./assets/public/images/loading.gif " alt=" ">
                        </div>
                    </ng-container>





                </div>
            </div>
        </div>

    </ng-template>
</app-template>