<!-- Listado de componentes embebidos-->
<!-- [ngClass]="{'box-principal' : borderBox}"-->


<app-template>

    <ng-template app-content componentName="mainContentNotes">
        <app-notes></app-notes>
    </ng-template>

    <ng-template app-content componentName="mainExports">
        <app-list-exports></app-list-exports>
    </ng-template>


    <ng-template app-content componentName="mainCVV">
        <app-cvv></app-cvv>
    </ng-template>



    <ng-template app-content componentName="mainContentModalApp">
        <div class="capa-menu-2" *ngIf="buttomModal" (click)="buttomModal =!buttomModal"> </div>
        <div style="overflow:auto;" [ngClass]="{'d-none':animate_init_bottom,'animate__slideInDown':buttomModal,animate__slideOutUp:!buttomModal } " class=" animate__animated animate__faster ak-modal-top p-2 ">

            <div class="body pb-3 pt-3" style="overflow:auto;">

                <div class="container">

                    <div class="font-weight-bold mt-1  text-center w-100">
                        <i class="fas fas fa-user text-secondary mr-1 "></i> Información Personal

                        <hr>
                    </div>
                    <nav class=" ak-menu-tsct ">

                        <ul class="ak-menu-tsct pl-0 ">
                            <ng-container *ngIf="!workflowStatusMenu">
                                <div class=" col-12  p-t-1 p-b-1">
                                    <img src="./assets/public/images/loading.gif" alt="">
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let stage of workflowStatusMenu ; let first = first; let i = index">
                                <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let i2 = index">
                                    <li (click)="dropDownTransitionsWfs[step?.alternateName] = !dropDownTransitionsWfs[step?.alternateName] " [ngClass]="{'active':transitionsWfs[sectionName]?.active?.step == step?.alternateName &&  step?.sections?.length < 2, 'completed': (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.state == 'COMPLETED'}"
                                        *ngIf="step?.showTitles && step?.sections?.length > 0">
                                        <ng-container *ngIf="step?.sections?.length > 1">

                                            <div class="d-flex w-100 active-cursor">

                                                <div>
                                                    <a href="javascript:void(0) ">


                                                        <span> {{step?.name | i18n}} </span>

                                                    </a>

                                                </div>
                                                <div class=" ml-auto" style="color: #ccc;">
                                                    <i *ngIf="!dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-down"></i>
                                                    <i *ngIf="dropDownTransitionsWfs[step?.alternateName]" class="fas fa-chevron-up"></i>

                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="step?.sections?.length < 2">

                                            <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">

                                                <ng-container *ngIf="section?.additionalType != 'modal'">
                                                    <a (click)="redirectSection(workflowCodeActive,stage?.alternateName,step?.alternateName,section?.alternateName);nodeActive = section.alternateName" href="javascript:void(0) ">
                                                        <span> {{step?.name | i18n}} </span>

                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>

                                        <div class="ak-menu-tsct2">
                                            <ul *ngIf="step?.sections?.length > 1 && dropDownTransitionsWfs[step?.alternateName]">
                                                <ng-container *ngFor="let section of step?.sections ; let first3 = first; let i3 = index">
                                                    <li [ngClass]="{'active': nodeActive == section.alternateName,'completed': (workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'}" *ngIf="section?.additionalType != 'modal'">
                                                        <a (click)="$event?.stopPropagation();redirectSection(workflowCodeActive,stage?.alternateName,step?.alternateName,section?.alternateName);nodeActive = section.alternateName" href="javascript:void(0) ">
                                                            <span *ngIf="(workflowStatus[stage?.alternateName])?.steps[step?.alternateName]?.sections[section?.alternateName]?.state == 'COMPLETED'" class="fas fa-check" style="font-size: 10px;color: #999;"></span>

                                                            <span> {{section?.sectionTitle | i18n}} </span>

                                                        </a>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </li>
                                </ng-container>

                            </ng-container>

                        </ul>


                    </nav>
                </div>
            </div>
        </div>

    </ng-template>
</app-template>

<div class="ak-full-modal bg-gray " [ngClass]="{'size-menu-left': sizeMenuLeft}" *ngIf="showChangePass">
    <div class="header">
        <div class="d-flex bd-highlight pr-2 pl-2 pb-2">
            <div *ngIf="!sizeMenuLeft" class=" pt-1">
                <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
            </div>
            <div class="  pr-0 pl-md-3 flex-grow-1 bd-highlight">
                <h4 class=" pt-3" [innerHTML]="'@i18n-change-password' | i18n"></h4>
            </div>
            <div class=" bd-highlight pt-2 ">
                <a class=" text-dark" href="javascript:void(0)" (click)="setShowChangePassword(false)">
                    <img class="size-img close" src="assets/public/images/close.svg">
                </a>
            </div>
        </div>
    </div>
    <div class="body">
        <app-change-user-password></app-change-user-password>
    </div>
</div>

<div class="container">
    <div class="row">
        <ng-container *ngTemplateOutlet="templates['topAlert'];"></ng-container>
    </div>
</div>

<div [ngClass]="{'mt-4':this.wo}"></div>



<ng-container *ngIf="isAuth">
    <!--
    <div class="container" *ngIf="!this.wo">
        <akeela-breadcrumb (response)="eventBreadcrumb($event)">
        </akeela-breadcrumb>
    </div>
    <div class="d-none" *ngIf="showSubHeaderStep" [ngClass]="{'d-block':showSubHeaderStep}">

        <app-breadcrumb [currentWorkflowDefault]="currentWorkflowDefault" [title]="title" [subtitle]="subtitle" [link]="link" [currentWorkflowChange]="currentWorkflowChange" (response)="get_response_breadcrub($event)"></app-breadcrumb>

    </div>
     -->

    <!-- <div class=" container mt-2  {{ borderBox?'box-principal' :'' }} {{ showWorkflow ? '' : 'd-none' }}"> -->

    <div [ngClass]="{'container-fluid': (workflowCodeActive != 'CLIENT_DATA' && workflowCodeActive != 'CLIENT_DATA_REDUCED') ||  !containerFluid ,'container': (workflowCodeActive == 'CLIENT_DATA' || workflowCodeActive == 'CLIENT_DATA_REDUCED') ||  containerFluid }"
        class=" mt-2">



        <div *ngIf="optionsDataWF?.showMenu">
            <div *ngIf="localStorage.getItem('clientName')" class="float-right text-secondary  mt-2">

                <span *ngIf="localStorage.getItem('clientlegalName')">
                    {{localStorage.getItem('clientlegalName')}}

                </span>

                <span *ngIf="localStorage.getItem('clientName') && !localStorage.getItem('clientlegalName')">
                    {{localStorage.getItem('clientName')}}

                </span>

            </div>

            <div class="activate-cursor text-secondary    pt-2 " (click)="redirectDashboard()">

                <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i> Volver
                <hr *ngIf="containerFluid">


            </div>
        </div>

        <div class="mt-2 mb-3" *ngIf="optionsDataWF?.showMenu && (workflowCodeActive == 'CLIENT_DATA' || workflowCodeActive == 'CLIENT_DATA_REDUCED')">
            <div class="row">
                <div class="col-12 ">

                    <!--
                    <div>
                        <img class="pt-1" style="width: 36px;" src="./assets/public/images/loading.gif" alt="">
                    </div>
 -->

                    <div (click)="openModalMenu()" class="  pb-0 pt-1">
                        <hr class="mt-0 mb-2 pt-0 pb-0" style="    margin-left: -15px;
                                        margin-right: -15px;">
                        <span class="text-primary fz-14  fz-16">
                                            Saltar a 
                                                                                </span>
                        <i class="fas fa-chevron-down float-right text-primary pt-1"></i>
                        <hr class="mt-2 mb-0 pt-0 pb-0" style="    margin-left: -15px;
                                        margin-right: -15px;">
                    </div>


                </div>
            </div>



        </div>

        <!-- <div class="container mt-2"> -->
        <workflow-embed-components></workflow-embed-components>

        
        <main>
            <router-outlet></router-outlet>
        </main>
    </div>

</ng-container>


<div class="container mt-2" *ngIf="(!showWorkflow) || (!isAuth)">
    <div class="col-12 col-md-8 p-t-1 p-b-1">
        <img src="./assets/public/images/loading.gif" alt="">
    </div>
</div>