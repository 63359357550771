// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.




//test
// clientCode: 'TEST_USER',

//desarrollo
//production: false,
// clientCode: 'ARN_USER',
// http://3.89.248.120:8080

//prod
// production: true,
// clientCode: 'QUALITY_USER_ARN',
// http://167.172.19.250:8080


const workflow = 'ACCOUNT_OPENING'; // Esté siempre será el por defecto
const workflow2 = 'DOC_SUPPORT';


export const environment = {
    production: true,
    code:'KOI_PROD',
    procedureMail: 'Apertura de Cuenta',
    urlFront: 'https://user.koicasadebolsa.com',
    urlClient: 'https://arn.koicasadebolsa.com',
    workflow: workflow,
    workflow2: workflow2,
    defaultInitRoute: 'dashboard/' + workflow + '/stages/ACCOUNT_SELECTION/steps/ACCOUNT_SELECTION',
    defaultInitRouteAuthenticated: 'private/' + workflow + '/stages/LIST_TRANSACTIONS/steps/LIST_TRANSACTIONS',
    staticLinks: {
        login: 'public/' + workflow + '/stages/LOGIN_' + workflow + '/steps/LOGIN_' + workflow,
        register: 'public/' + workflow + '/stages/CREATE_USER_' + workflow + '/steps/CREATE_USER_' + workflow,
        listTransactions: 'private/' + workflow + '/stages/LIST_TRANSACTIONS/steps/LIST_TRANSACTIONS',
        requirementsUpload: 'private/' + workflow2 + '/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS',
        detailClientResumen: 'private/' + workflow + '/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING',
        accountContractAnalisys: 'private/' + workflow + '/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/ACCOUNT_OPENING_ANALYSIS',
        listAccountOpening: 'private/' + workflow + '/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING',
        viewClient: 'private/' + workflow + '/stages/LIST_CLIENTS/steps/VIEW_CLIENT',
        listClients: 'private/' + workflow + '/stages/LIST_CLIENTS/steps/LIST_CLIENTS',
        viewAccountOpeningAnalisys: 'private/' + workflow + '/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/REVISIONS_TAB',
        registerUser: 'private/' + workflow + '/stages/CREATE_USER_ACCOUNT_OPENING/steps/CREATE_USER_ACCOUNT_OPENING',
        workspace: 'private/' + workflow + '/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/USER_WORKSPACES',
        firstStep: 'private/' + workflow + '/stages/CLIENT_IDENTIFICATION/steps/BUSINESS_IDENTITY',
        resumenUser: 'private/' + workflow + '/stages/ACCOUNT_CONTRACT_ANALYSIS/steps/REVISIONS_TAB'


    },
    theme: {
        images: 'KOI',
        backgroundColor: '#007bff',
        backgroundColorBorderMail: '#81DAF5',
        fontColor: '#FFFFFF',
        site: {
            name: 'KOI',
            title: 'ARN | USER',
            url: 'http://koi.co',
            supportMail: 'online@koicasadebolsa.com'
        },
        image: {
            active: true,
            urlImageHeader: 'https://arn.koicasadebolsa.com/assets/koi/public/images/koi.jpg'
        },
        textFooterMail: '',
        sizeImageLogoNavbar: '3',
        sizeImageLogoNavbarMovil: '3',
        sizeImageLogoMenu: '3',
        
        logoConf: {
            theme: {
                sure: {
                    urlLogo: 'assets/koi/public/images/koi.jpg',
                    urlLogoMin:'assets/koi/public/images/koi.jpg',
                },

                dark: {
                    urlLogo: 'assets/koi/public/images/koi.jpg',
                    urlLogoMin: 'assets/koi/public/images/koi.jpg',
                }
            },
            themeColorDefault: 'sure',
            themeColorNav: 'sure',
            themeColorNavAuth: 'sure',
            themeColorMenu: 'sure',
            themeColorNavModal: 'sure',
            themeColorPdf: 'sure',


            typeDefault: null,
            typeNav: 1,
            typeNavAuth: 1,
            typeMenu: 1,
            typePdf: 1,
            typeNavModal: null,
        }
    },
    apiConfig: {
        useMock: false,
        urlApi: 'https://services.koicasadebolsa.com:443/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: true
    },
    appPropertiesConfig: {
        useMock: false,
        urlApi: 'https://services.koicasadebolsa.com:443/',
        uriPropertiesPrefix: 'app_properties'
    },
    appEndPontDefaultConfig: {
        workflowState: 'workflowState',
        stepState: 'stepState',
        transitions: 'transitions',
        sectionsState: 'sectionsState',
        menu: 'menu',
        appPropertyMenu: 'optionsMenu',
        transactionCode: 'transactionCode',
        stageStatus: 'stageStatus',
        sidebarTopActive: 'WORKSPACES',
        sidebarLeftActive: 'WORKSPACES',
        showPayroll: true,
        isFieldNotRequired: true,
        ac_pending:{
            dangerMts : 240,
            warningMts : 120
        },

    },
    i18nConfig: {
        tagOpen: '{',
        tagClose: '}',
        constants: {
            clientName: 'KOI',
            clientNameShort: 'KOI',
            bankAccountInstitution: 'KOI',
            url: 'https://koicasadebolsa.com/',
            supportMail: 'info@koicasadebolsa.com',
            urldjpn: '/assets/koi/public/documents/djpn.docx',
            urldc: '/assets/koi/public/documents/dc.doc',

        }
    },
    middleware: {
        environment: 'accounts',
        module: 'REQUIREMENT'
    },
    wfConfig: {
        useMock: false,
        urlApi: 'https://services.koicasadebolsa.com:443/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: true,
    },
    security: {
        urlApi: 'https://services.koicasadebolsa.com:443/authorization-manager/api/v1',
        clientCode: 'KOI_USER',
        uriLogin: 'security/login',
        uriLogout: 'security/logout',
        urlForgotPassword: 'security/forgot-password',
        uriRefreshToken: 'refresh',
        urlSecurityApi: 'https://services.koicasadebolsa.com:443/person'
    },
    paginator: {
        show: true,
        page: 0,
        size: 25,
        maxPages: 10
    },
    auth: {
        activeEncrypt: true,
        hasProfiles: true,
        inactivity: {
            active: true
        }
    },
    formsValidations: {
        login: {
            username: {
                minLength: 6,
                maxLength: 12
            },
            password: {
                minLength: 8,
                maxLength: 12
            }
        }
    },
    requirements: {
        file: {
            mb_max_filesize: 1
        },
        fieldsResponseShow: {
            observationInside: true,
            reasonIncompleted: true
        },
        responseOptionalFieldsIndividualResponse: {
            observationInside: true,
            reasonIncompleted: []
        }
    },
    akeelaTablesConfig: {
        workspaces: {
            columns: [
                {
                    alignContent: 'left',
                    fixed: 'right',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Nombre',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Clave',
                    cssCustom: '',
                    enableLeftBorder: true
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Producto',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Plan',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Vista',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Agencia',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Responsable',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Estatus Solicitud',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Estatus Agencia',
                    cssCustom: '',
                    enableRightBorder: true

                },
                {
                    alignContent: 'center',
                    fixed: 'left',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: '',
                }
            ]
        },
        listTransactionsUser: {
            columns: [
                {
                    alignContent: 'center',
                    fixed: 'right',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '#',
                    cssCustom: ''
                },
                {
                    alignContent: 'center',
                    maxWidth: '20',
                    minWidth: '10',
                    html: '',
                    enableLeftBorder: true,
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '110',
                    minWidth: '90',
                    html: 'Solicitud',
                    cssCustom: '',
                    attr_order: 'transaction_number'

                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Solicitante',
                    cssCustom: '',
                    attr_order: 'holder_name'


                },
                {
                    alignContent: 'left',
                    maxWidth: '100',
                    minWidth: '60',
                    html: 'Agencia',
                    cssCustom: '',
                    attr_order: 'channel_name'

                },
                {
                    alignContent: 'left',
                    maxWidth: '300',
                    minWidth: '250',
                    html: 'Ejecutivo',
                    cssCustom: '',
                    attr_order: 'sales_manager'

                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '140',
                    html: 'Estatus del Solicitud',
                    cssCustom: '',
                    attr_order: 'state_name'
                },
                {
                    alignContent: 'left',
                    minWidth: '120',
                    html: 'Tiempo de Resp.',
                    cssCustom: '',
                },
                {
                    alignContent: 'center',
                    maxWidth: '15',
                    minWidth: '15',
                    html: '',
                    cssCustom: '',
                    enableRightBorder: true,
                },
                {
                    alignContent: 'center',
                    fixed: 'left',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: '',
                }
            ]
        },
        listTransactions: {
            columns: [
                {
                    alignContent: 'center',
                    fixed: 'right',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '250',
                    minWidth: '150',
                    html: 'Solicitante',
                    cssCustom: '',
                    enableLeftBorder: true,
                },
                {
                    alignContent: 'left',
                    maxWidth: '100',
                    minWidth: '60',
                    html: 'Solicitud',
                    cssCustom: '',

                },
                {
                    alignContent: 'left',
                    maxWidth: '100',
                    minWidth: '60',
                    html: 'Estatus',
                    cssCustom: '',

                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Recibido',
                    cssCustom: 'Recibido',

                },

                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Última Act.',
                    cssCustom: '',
                    enableRightBorder: true,

                },
                {
                    alignContent: 'right',
                    fixed: 'left',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: '',
                }
            ]
        },
        listRelatedTransactions: {
            columns: [
                {
                    alignContent: 'center',
                    fixed: 'right',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: ''
                },
                {
                    alignContent: 'left',
                    maxWidth: '130',
                    minWidth: '110',
                    html: 'Solicitud',
                    cssCustom: '',
                    enableLeftBorder: true,

                },
                {
                    alignContent: 'left',
                    maxWidth: '150',
                    minWidth: '110',
                    html: 'Estatus',
                    cssCustom: '',

                },
                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Fecha de Estado',
                    cssCustom: 'Recibido',

                },

                {
                    alignContent: 'left',
                    maxWidth: '200',
                    minWidth: '150',
                    html: 'Última Act.',
                    cssCustom: '',
                    enableRightBorder: true,

                },
                {
                    alignContent: 'center',
                    fixed: 'left',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: '',
                }
            ]
        },
        listClientsUser: {
            columns: [
                {
                    alignContent: 'center',
                    fixed: 'right',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: '',

                },
                {
                    alignContent: 'left',
                    maxWidth: '300',
                    minWidth: '250',
                    html: 'Nombre',
                    cssCustom: '',
                    enableLeftBorder: true,
                    attr_order: 'custom_name'
                },
                {
                    alignContent: 'left',
                    maxWidth: '300',
                    minWidth: '250',
                    html: 'Ejecutivo',
                    cssCustom: '',
                    attr_order: 'ejecutive'

                },
                {
                    alignContent: 'left',
                    maxWidth: '150',
                    minWidth: '100',
                    html: 'Fecha Creación',
                    cssCustom: '',
                    attr_order: 'update_date',

                },
                {
                    alignContent: 'left',
                    maxWidth: '80',
                    minWidth: '80',
                    html: 'Solicitudes',
                    cssCustom: '',
                    enableRightBorder: true,

                },
                {
                    alignContent: 'center',
                    fixed: 'left',
                    maxWidth: '20',
                    minWidth: '20',
                    html: '',
                    cssCustom: '',
                }
            ]
        }
    },
    localStorageConfig: {
        activeEncrypt: true
    },
    showOperationDataResumen: true,
    showTransactionsRelatedResumen: true,
    showTips: true,
    sendMailNewClient: true,
    visor: 'alternative'
};


